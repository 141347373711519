import React, { useEffect } from "react";
import { trans } from "../../../../../../Navbar/Navbar";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import i18n from "../../../../../../../i18n";

const getLocationName = async (lat, lng) => {
  const geocoder = new window.google.maps.Geocoder();
  const latlng = new window.google.maps.LatLng(lat, lng);
  let loc = "";
  await geocoder.geocode({ location: latlng }, function (results, status) {
    if (status === "OK") {
      if (results[0]) {
        loc = results[0].formatted_address;
      } else {
        console.log("No results found");
      }
    } else {
      console.log("Geocoder failed due to: " + status);
    }
  });
  return loc;
};

function Flatnies({ from, to }) {
  const [location, setLocation] = React.useState({ from: "", to: "" });
  const centerFrom = {
    lat: from.lat,
    lng: from.lng,
  };
  const centerTo = {
    lat: to.lat,
    lng: to.lng,
  };
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo",
    language: i18n.language,
  });

  useEffect(() => {
    (async () => {
      if (isLoaded) {
        const from = await getLocationName(centerFrom.lat, centerFrom.lng);
        const to = await getLocationName(centerTo.lat, centerTo.lng);
        setLocation({ to, from });
      }
    })();
  }, [isLoaded, centerFrom.lat, centerFrom.lng, centerTo.lat, centerTo.lng]);

  return (
    <>
      <div className='Flatnies d-flex flex-column gap-2'>
        <div className='current d-flex align-items-center gap-1'>
          <span className='fs-14-400 text-color'>
            {trans("vendor.orders.car_location")}
          </span>
          <span className='fs-16-500'>
            {location.from ? location.from : "loading..."}
          </span>
        </div>
        <div className='interFace d-flex align-items-center gap-1'>
          <span className='fs-14-400 text-color'>
            {trans("vendor.orders.car_interface")}
          </span>
          <span className='fs-16-500'>
            {location.to ? location.to : "loading..."}
          </span>
        </div>
      </div>
    </>
  );
}

export default Flatnies;
