export function getFileType(fileName) {
    const imageExtensions = ["jpeg", "jpg", "png"];
    const docExtensions = ["doc", "docx", "pdf"];
  
    const extension = fileName.split(".").pop().toLowerCase();
  
    if (imageExtensions.includes(extension)) {
      return "img";
    } else if (docExtensions.includes(extension)) {
      return "doc";
    } else {
      return "unknown"; // or handle other file types as needed
    }
  }