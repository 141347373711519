import React from "react";
import RatingView from "../../../../Components/RatingView/RatingView";
import image from "../../Assets/Rectangle.png";
import i18n from "../../../../i18n";
const formatDate = (dateString, language) => {
  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthsAr = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const date = new Date(dateString);
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  if (language === "ar") {
    return `${day} ${monthsAr[monthIndex]}, ${year}`;
  } else {
    return `${monthsEn[monthIndex]} ${day}, ${year}`;
  }
};

function Customer({ buyer, rate, comment, created_at }) {
  return (
    <div className='Customer border r-07 p-3'>
      <div className='Top d-flex justify-content-between'>
        <div className='info d-flex gap-3 align-items-center'>
          <div
            className='image bg-image'
            style={{ backgroundImage: `url(${buyer.image})` }}
          ></div>
          <div className='text'>
            <div className='name'>{buyer.name}</div>
            <div className='date'>{formatDate(created_at, i18n.language)}</div>
          </div>
        </div>
        <RatingView fullStars={rate} />
      </div>
      {/* text */}
      <p className='mt-3'>{comment}</p>
    </div>
  );
}

export default Customer;
