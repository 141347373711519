import React, { useState } from "react";
import { trans } from "../../../../Components/Navbar/Navbar";
import Customer from "./Customer";
import Popup from "../../../../Components/Popup/Popup";
import AddRating from "../../../../Components/AddRating/AddRating";
import "./CustomerRating.css";
import { basedDomin } from "../../../../Api/basedDomin";
import axios from "axios";
import { ErrorComponent, SuccsesComponent } from "../../../../Others/Error";
import { useNavigate } from "react-router-dom";

function CustomerRating({ data, vendor_id, order_id }) {
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");
  const token = localStorage.getItem("token");
  //Psost rates
  const navigate = useNavigate();
  const addSomeRates = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${basedDomin}/buyer/orders/rate`,
        {
          order_id,
          vendor_id,
          rate,
          comment,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      SuccsesComponent(data.message);
    } catch (e) {
      ErrorComponent(e, navigate);
    } finally {
      setLoading(false);
    }
  };
  const [addRating, setAddRating] = useState({
    status: false,
    text: "تقييم البائع",
  });
  return (
    <>
      {addRating.status && (
        <Popup state={addRating} setState={setAddRating} width='460px'>
          <form onSubmit={addSomeRates}>
            <AddRating {...{ rate, setRate }} />
            <div className='add-text d-flex flex-column gap-3 mt-32'>
              <div className='fs-16-500'>{trans("rating_vendor.add")}</div>
              <textarea
                className='border r-07 p-3'
                placeholder={trans("rating_vendor.place")}
                rows='3'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <button className='btn-blue'>
                {trans("rating_vendor.send")}
              </button>
            </div>
          </form>
        </Popup>
      )}

      <div className='CustomerRating'>
        <h3 className='fs-60-600'>{trans("service_provider.comments")}</h3>
        <div className='mt-4 d-flex flex-column gap-3'>
          {data.map((rate, idx) => (
            <Customer key={idx} {...rate} />
          ))}

          <div className='view-all'>
            <button className='border-0 bg-transparent fs-16-600'>
              {data.length > 2 && trans("service_provider.all_view")}
            </button>
          </div>
        </div>
        {/* Add Rating */}
        <button
          className='btn-border-blue mt-32 px-5'
          onClick={() => {
            setAddRating({ ...addRating, status: true });
          }}
        >
          {trans("service_provider.add_rate")}
        </button>
      </div>
    </>
  );
}

export default CustomerRating;
