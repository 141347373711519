function GetYears(setState) {
  const currentYears = new Date().getFullYear();
  const lastYears = currentYears - 50;
  const ArrayYears = [];
  for (let i = lastYears; i <= currentYears; i++) {
    ArrayYears.push({ value: i, label: i });
  }
  setState(ArrayYears.sort((a, b) => b.value - a.value));
}

export default GetYears;
