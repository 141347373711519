import { useRecoilValue } from "recoil";
import { ActiveUsers } from "../Recoil/ActiveUsers";
import { useEffect, useState } from "react";

export default function useCheckActiveUsers(id) {
  const onlineUsers = useRecoilValue(ActiveUsers);
  const [isOnline, setIsOnline] = useState(false);
  useEffect(() => {
    const idExists = onlineUsers.some((item) => item.id === id);

    // Update state based on ID existence
    if (idExists) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  }, [id, onlineUsers]);

  return isOnline;
}
