import React, { useEffect, useState } from "react";
import { ReactComponent as Shape } from "../Assets/recoverCode.svg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import "./Inputs.css";
import { basedDomin } from "../../Api/basedDomin";
import axios from "axios";
import { ErrorComponent, SuccsesComponent } from "../../Others/Error";
import { useRecoilValue } from "recoil";
import { RegisterTypeUser } from "../GlopalRecoilState/AllData";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase.config";
function VerifyCode() {
  const language = localStorage.getItem("i18nextLng");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const navigat = useNavigate();
  const { t, i18n } = useTranslation();
  const typeUser = useRecoilValue(RegisterTypeUser);
  // Validation State
  const [errorValidation, setErrorValidation] = useState();
  // Validation State
  const [code, setCode] = useState();
  // Validation Mobile Number
  const [seconds, setSeconds] = useState(59);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    let interval;

    // Reset timer when component mounts or when user clicks the button
    const resetTimer = () => {
      if (seconds === 59) setSeconds(58);
      setResendDisabled(true);
    };

    resetTimer(); // Initial setup

    // Start the timer interval
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else if (seconds === 0) {
        setResendDisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          "expired-callback": () => {},
        },
        auth,
      );
      window.recaptchaVerifier.render();
    }
  }

  const resendCodeToPhoneNumber = async () => {
    // Reset the timer when the button is clicked
    onCaptchVerify();
    const user = JSON.parse(localStorage.getItem("user"));
    const appVerifier = window.recaptchaVerifier;
    //signup with firebase
    const ph = user.mobile.substring(1);
    const formatPh = `+${user.code_country}${ph}`;

    console.log("appVerifier=> ", appVerifier);
    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formatPh,
        appVerifier,
      );
      window.confirmationResult = confirmationResult;
      console.log("confirmationResult=> ", confirmationResult);
      console.log("OTP sended successfully!");
      if (localStorage.getItem("user")) {
        navigat("/verify-code");
      }
    } catch (e) {
      console.log(e.message);
    }
    setResendDisabled(true);
    setSeconds(59);
  };

  // function Submit
  const formData = {
    code,
    mobile_or_email: user?.mobile,
    code_country: user?.code_country,
  };
  const onsubmit = async (e) => {
    e.preventDefault();
    console.log("formData=> ", formData);
    try {
      const res = await window.confirmationResult.confirm(code);
      const { operationType } = res;
      console.log("operationType=> ", operationType);
      if (operationType === "signIn") {
        try {
          const { data } = await axios.post(
            `${basedDomin}/public/checkcode`,
            formData,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          );
          SuccsesComponent(data.message);

          if (typeUser === "vendor") {
            if (user.is_register_vendor_data) {
              navigat("/");
            } else {
              navigat("/vendor");
            }
          } else {
            navigat("/");
          }
        } catch (error) {
          ErrorComponent(error, navigat, setErrorValidation);
        }
      }
    } catch (err) {
      err.message = "this Code is invalid";
      ErrorComponent(err, navigat, setErrorValidation);
    }
  };
  // function Submit
  return (
    <div
      className='SendCode form-cont bg-white py-4 py-md-5 px-3 px-md-5 d-flex flex-column gap-4 align-items-center'
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <div id='recaptcha-container'></div>
      <div className='logo'>
        <Shape />
      </div>
      <h3 className='fs-24-700'>{t("register.recover_code.title")}</h3>
      <p>{t("register.recover_code.desc")}</p>
      {/* Form */}
      <form className='full-width d-flex flex-column gap-4' onSubmit={onsubmit}>
        {/* Virify */}
        <div className='Inputs'>
          <VerificationInput
            length={6}
            passwordMode={true}
            validChars='A-Za-z0-9'
            classNames={{
              container: "container",
              character: `character ${errorValidation && "red"}`,
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
            onChange={(e) => {
              setCode(e);
            }}
          />
        </div>
        {/* Submit */}
        <button className='border-0 bg-green text-white button-font r-07 py-3 px-4'>
          {t("register.recover_code.confirm")}
        </button>
      </form>
      <div className='no-have d-flex align-items-center gap-1 '>
        <span>{t("register.recover_code.not_reseave")}</span>
        <button
          disabled={resendDisabled}
          style={{
            cursor: resendDisabled ? "not-allowed" : "pointer",
            border: "none",
            background: "transparent",
          }}
          onClick={resendCodeToPhoneNumber}
        >
          {seconds
            ? `${t("register.recover_code.resend")} ${seconds}`
            : t("register.recover_code.able_to_send")}
        </button>
      </div>
    </div>
  );
}

export default VerifyCode;
