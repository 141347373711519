import { trans } from "../Navbar/Navbar";

export default function ControllData({
  conditionToMore,
  conditionToLess,
  onMore,
  onLess,
}) {
  return (
    <>
      {conditionToMore && (
        <div
          className='text-center text-black-50'
          style={{ marginTop: "20px", cursor: "pointer" }}
          onClick={onMore}
        >
          {trans("more")}
        </div>
      )}
      {conditionToLess && (
        <div
          className='text-center text-black-50'
          style={{ marginTop: "20px", cursor: "pointer" }}
          onClick={onLess}
        >
          {trans("less")}
        </div>
      )}
    </>
  );
}
