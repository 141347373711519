import React, { useState } from "react";
import { ReactComponent as Logo } from "../Assets/logo.svg";
import { ReactComponent as User } from "../Assets/user.svg";
import { ReactComponent as Phone } from "../Assets/phone.svg";
import { ReactComponent as Email } from "../Assets/mail.svg";
import { ReactComponent as Lock } from "../Assets/lock.svg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { basedDomin } from "../../Api/basedDomin";
import axios from "axios";
import { ErrorComponent } from "../../Others/Error";
import { useRecoilState } from "recoil";
import { RegisterTypeUser } from "../GlopalRecoilState/AllData";
import PhoneInputComponent from "../PhoneInput";
import PhoneNumber from "google-libphonenumber";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
function SignUp() {
  var { t, i18n } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("");
  // Validation State
  const [errorValidation, setErrorValidation] = useState({});
  // Validation State
  // State Form
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPassword_confirmation] = useState();
  const [type, setType] = useRecoilState(RegisterTypeUser);

  const [phoneNumber, setPhoneNumber] = useState();
  const [codeCountry, setCodeCountry] = useState(20);
  const [selectedCountry, setSelectedCountry] = useState("EG");
  // State Form
  //send the phone number when he rigster for first time

  // Form Data

  const validatePhoneNumberForCountry = (phoneNumber) => {
    try {
      const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
      const parsedNumber = phoneUtil.parse(phoneNumber, selectedCountry);
      return phoneUtil.isValidNumberForRegion(parsedNumber, selectedCountry);
    } catch (error) {
      return false;
    }
  };

  // State Form
  // Form Data
  const formData = {
    name,
    mobile: phoneNumber,
    password,
    password_confirmation,
    email,
    type,
    code_country: String(codeCountry),
    lang: "ar",
    fcm_token: "fcm",
  };
  // Form Data
  // function Submit
  const navigat = useNavigate();

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          "expired-callback": () => {},
        },
        auth,
      );
      window.recaptchaVerifier.render();
    }
  }

  const onsubmit = async () => {
    try {
      const { data } = await axios.post(
        `${basedDomin}/public/register`,
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        },
      );
      localStorage.setItem("user", JSON.stringify(data.data));
      localStorage.setItem("token", data.data.token);
      if (localStorage.getItem("user")) {
        navigat("/verify-code");
      }
    } catch (error) {
      ErrorComponent(error, navigat, setErrorValidation);
    }
  };

  const submitAndSendVerfiyCode = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) throw new Error("Name is Reqired!");
      if (!formData.mobile) throw new Error("Mobile Number is Reqired!");
      if (!formData.password) throw new Error("Password is Reqired!");
      if (!formData.password_confirmation)
        throw new Error("Password Confirmation is Reqired!");
      if (formData.password !== formData.password_confirmation)
        throw new Error("Password Confirmation is not match the Password!");
      onCaptchVerify();
      const appVerifier = window.recaptchaVerifier;
      //signup with firebase
      const formatPh = `+${codeCountry}${phoneNumber}`;
      console.log(formatPh);

      console.log("appVerifier=> ", appVerifier);
      try {
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          formatPh,
          appVerifier,
        );
        window.confirmationResult = confirmationResult;
        console.log("confirmationResult=> ", confirmationResult);
        console.log("OTP sended successfully!");
      } catch (e) {
        ErrorComponent(e, navigat, setErrorValidation);
      }
      console.log("window.confirmationResult=> ", window.confirmationResult);
      if (window.confirmationResult) {
        onsubmit();
      }
    } catch (e) {
      ErrorComponent(e, navigat, setErrorValidation);
    }
  };
  // function Submit
  return (
    <div className='SignUp form-cont py-4 pt-md-5 px-3 px-md-5 d-flex flex-column gap-3 align-items-center'>
      <div id='recaptcha-container'></div>
      <div className='logo'>
        <Logo />
      </div>
      <h3 className='fs-24-700'>{t("register.sign_up.title")}</h3>
      <p>{t("register.sign_up.desc")}</p>
      {/* Type */}
      <div className='typs d-flex gap-3 full-width flex-column flex-md-row'>
        {/* Vendor */}
        <button
          className={`border-0 full-width p-3 r-07 ${
            type === "buyer" && "active"
          }`}
          onClick={() => {
            setType("buyer");
          }}
        >
          {t("register.sign_up.vendor")}
        </button>
        {/* Buyer */}
        <button
          className={`border-0 full-width p-3 r-07 ${
            type === "vendor" && "active"
          }`}
          onClick={() => {
            setType("vendor");
          }}
        >
          {t("register.sign_up.buyer")}
        </button>
      </div>
      {/* Form */}
      <form
        className='full-width d-flex flex-column gap-3'
        onSubmit={submitAndSendVerfiyCode}
      >
        {/* User Name */}
        <div className='d-flex flex-column gap-2'>
          <div
            className={`input-sign d-flex gap-2 p-3 r-10  ${
              errorValidation.hasOwnProperty("name") && "border border-red"
            }`}
          >
            <span className='icon'>
              <User />
            </span>
            <input
              className={`full-width border-0 outline-0`}
              type='text'
              placeholder={t("register.sign_up.name")}
              id='name'
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          {errorValidation.hasOwnProperty("name") && (
            <span className='text-error fs-14-400'>
              {errorValidation.name[0]}
            </span>
          )}
        </div>
        {/* Phone Number */}
        <PhoneInputComponent
          {...{
            errorValidation,
            t,
            phoneNumber,
            setPhoneNumber,
            codeCountry,
            setCodeCountry,
            selectedCountry,
            setSelectedCountry,
          }}
        />
        {/* Email */}
        <div className='d-flex flex-column gap-2'>
          <div
            className={`input-sign d-flex gap-2 p-3 r-10  ${
              errorValidation.hasOwnProperty("email") && "border border-red"
            }`}
          >
            <span className='icon'>
              <Email />
            </span>
            <input
              className={`full-width border-0 outline-0`}
              type='email'
              id='email'
              placeholder={t("register.sign_up.email")}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          {errorValidation.hasOwnProperty("email") && (
            <span className='text-error fs-14-400'>
              {errorValidation.email[0]}
            </span>
          )}
        </div>
        {/* password */}
        <div className='d-flex flex-column gap-2'>
          <div
            className={`input-sign d-flex gap-2 p-3 r-10  ${
              errorValidation.hasOwnProperty("password") && "border border-red"
            }`}
          >
            <span className='icon'>
              <Lock />
            </span>
            <input
              className={`full-width border-0 outline-0`}
              type='password'
              placeholder={t("register.sign_up.password")}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {errorValidation.hasOwnProperty("password") && (
            <span className='text-error fs-14-400'>
              {errorValidation.password[0]}
            </span>
          )}
        </div>
        {/* confirm password */}
        <div className='d-flex flex-column gap-2'>
          <div
            className={`input-sign d-flex gap-2 p-3 r-10  ${
              errorValidation.hasOwnProperty("password_confirmation") &&
              "border border-red"
            }`}
          >
            <span className='icon'>
              <Lock />
            </span>
            <input
              className={`full-width border-0 outline-0`}
              type='password'
              placeholder={t("register.sign_up.confirm_password")}
              onChange={(e) => {
                setPassword_confirmation(e.target.value);
              }}
            />
          </div>
          {errorValidation.hasOwnProperty("password_confirmation") && (
            <span className='text-error fs-14-400'>
              {errorValidation.password_confirmation[0]}
            </span>
          )}
        </div>
        {/* Submit */}
        <button className='border-0 bg-green text-white button-font r-07 py-3 px-4'>
          {t("register.sign_up.login")}
        </button>
      </form>
      <div className='no-have d-flex align-items-center gap-1'>
        <span>{t("register.sign_up.you_have")}</span>
        <Link to={"/register/sign-in"}>{t("register.sign_up.log_in")}</Link>
      </div>
    </div>
  );
}

export default SignUp;
