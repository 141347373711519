import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const alert_toast = (icon, text, Swal) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: text,
  });
};
export function ErrorComponentAxios(error, navigat, setErrorValidation = null) {
  switch (error.status) {
    case 401:
      navigat("/register/sign-in");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      break;
    case 403:
      navigat("/verify-code");
      break;
    case 404:
      alert_toast("error", error.message, Swal);
      break;
    case 422:
      if (setErrorValidation) {
        setErrorValidation(error.errors);
      }
      alert_toast("error", error.message, Swal);
      break;
    case 400:
      alert_toast("warning", error.message, Swal);
      break;
    case 500:
      alert_toast("error", "Server error occurred", Swal);
      break;
    default:
      alert_toast("error", error.message, Swal);
      break;
  }
}

export function ErrorComponent(error, navigat, setErrorValidation = null) {
  if (!error.response) alert_toast("error", error.message, Swal);
  else {
    if (error.response.status === 401) {
      navigat("/register/sign-in");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    } else if (error.response.status === 403) {
      navigat("/verify-code");
    } else if (error.response.status === 404) {
      navigat("/route-error");
    } else if (error.response.status === 422) {
      if (setErrorValidation !== null) {
        setErrorValidation(error.response.data.errors);
      }
      alert_toast("error", error.response.data.message, Swal);
    } else if (error.response.status === 400) {
      alert_toast("warning", error.response.data.message, Swal);
    } else if (error.response.status === 500) {
      alert_toast("error", "Server error occurred", Swal);
    }
  }
}
export function SuccsesComponent(msg) {
  alert_toast("success", msg, Swal);
}
export function TextNotification(msg) {
  alert_toast("info", msg, Swal);
}
