import React from "react";
import "./TheOrders.css";
import { trans } from "../../../../../../../Components/Navbar/Navbar";
import Spares from "./Cards/Spares";
import Loader from "../../../../../../../Components/Loader/Loader";
import MaintenanceServices from "./Cards/MaintenanceServices";
import FlatniessCard from "./Cards/FlatniessCard";
import NothingLottie from "../../../../../../../Components/NothingLottie/NothingLottie";
import ControllData from "../../../../../../../Components/ControllData";

function TheOrders({ orders, loader, exist, getOrdersFauction }) {
  // Get Orders Func
  const { data, next, prev } = orders;
  return (
    <div className='TheOrders d-flex flex-column flex-grow-1 py-4 mt-4'>
      <h3 className='fs-24-600 mb-4 px-3 px-md-4'>
        {trans("vendor.orders.all_orders")}
      </h3>
      {loader ? (
        <div className='flex-c bg-white r-07 mt-5' style={{ height: "350px" }}>
          <Loader width={"150px"} />
        </div>
      ) : exist ? (
        <>
          <div className='cards'>
            {data.map((item) =>
              item.service_type === "spare_parts" ? (
                <Spares data={item} key={item.id} />
              ) : item.service_type === "maintenance_services" ? (
                <MaintenanceServices data={item} key={item.id} />
              ) : (
                <FlatniessCard data={item} key={item.id} />
              ),
            )}
            <ControllData
              {...{
                conditionToMore: data.length >= 10 && next,
                conditionToLess: data.length > 10 && !next,
                onMore: () => getOrdersFauction(next, true),
                onLess: () => getOrdersFauction(prev),
              }}
            />
          </div>
        </>
      ) : (
        // No Orders
        <div className='flex-c flex-grow-1'>
          <div
            className='flex-c flex-column gap-2'
            data-aos='zoom-out'
            data-aos-duration='1500'
          >
            <div className=''>
              <NothingLottie width={"300px"} />
            </div>
            <p className='fs-20-600 '>{trans("nothing_orders")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default TheOrders;
