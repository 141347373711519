import React, { useEffect, useRef, useState } from "react";
import TextMessage from "../ContentMessages/Components/TextMessage/TextMessage";
import { useRecoilState, useRecoilValue } from "recoil";
import { changeUserId, messagesChanged } from "../../GlopalStateRecoil/AllData";
import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import i18n from "../../../../i18n";
import { useNavigate } from "react-router-dom";
import { ErrorComponent } from "../../../../Others/Error";
import { trans } from "../../../Navbar/Navbar";
import Loader from "../../../Loader/Loader";
import { IsFlatness, UserDefaultLocation } from "../../../../Recoil/location";
import useGetCarLocation from "../../../../hooks/useSendCarLocation";

const stratToMoveStyles = {
  textAlign: "center",
  padding: "5px 10px",
  borderRadius: "9px",
  color: "white",
  backgroundColor: "var(--bs-warning)",
  margin: "10px 0",
  cursor: "pointer",
};

function ContainerMessages({
  is_done_deal,
  is_moved_car,
  is_arrived_car,
  order,
 
}) {
  const refCont = useRef();
  const Messages = useRecoilValue(messagesChanged);
  const userId = useRecoilValue(changeUserId);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const userDefaultLocation = useRecoilValue(UserDefaultLocation);
  const [isFlatness, setIsFlatness] = useRecoilState(IsFlatness);

  // const carLocation = useGetCarLocation(
  //   order.service_type === "flatness" && flatnessStatus.is_moved,
  // );
  useEffect(() => {
    if (order.service_type === "flatness") {
      setIsFlatness({
        isFlastness: order.service_type === "flatness",
        is_moved: is_moved_car,
        is_arrived: is_arrived_car,
      });
    }
  }, [is_moved_car, is_arrived_car, order.service_type]);
  // Scroll To Bottom Fun
  const scrollToBottom = () => {
    let elHeight = document.getElementById("message-text")?.clientHeight;
    refCont?.current?.scrollTo({ top: elHeight, behavior: "smooth" });
  };
  const updateCarAction = async (action) => {
    setLoader(true);
    try {
      const { data } = await axios.post(
        `${basedDomin}/vendor/orders/offers/action-car`,
        { order_id: order.id, action_type: action },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (action === "is_moved_car") {
        setIsFlatness({ ...isFlatness, is_moved: true });
      } else {
        setIsFlatness({ ...isFlatness, is_arrived: true });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const sendTheDefaultLocation = async (loaction) => {
    try {
      const { data } = await axios.post(
        `${basedDomin}/vendor/update-location`,
        { lat: userDefaultLocation.lat, long: userDefaultLocation.lng },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      await updateCarAction("is_moved_car");
    } catch (error) {
      ErrorComponent(error, navigate);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [Messages.length]);
  return (
    <>
      <div
        ref={refCont}
        id='message-text'
        className='cont d-flex flex-column-reverse gap-2 flex-grow-1'
      >
        {Messages.map((item, idx) => (
          <TextMessage {...item} key={item.id} />
        ))}
      </div>
      {order.service_type === "flatness" && (
        <>
          {is_done_deal && !is_moved_car && !isFlatness.is_moved && (
            <div
              style={stratToMoveStyles}
              onClick={() => sendTheDefaultLocation()}
            >
              {loader ? (
                <Loader width={"25px"} />
              ) : (
                trans("requestes_message.flatness.vendor.will_start_move")
              )}
            </div>
          )}
          {isFlatness.is_moved && !isFlatness.is_arrived && (
            <div
              style={stratToMoveStyles}
              onClick={() => updateCarAction("is_arrived_car")}
            >
              {loader ? (
                <Loader width={"25px"} />
              ) : (
                trans("requestes_message.flatness.vendor.did_start_move")
              )}
            </div>
          )}
          {isFlatness.is_arrived && (
            <div
              style={stratToMoveStyles}
              onClick={() => updateCarAction("is_arrived_car")}
            >
              {loader ? (
                <Loader width={"25px"} />
              ) : (
                trans("requestes_message.flatness.vendor.arrived")
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ContainerMessages;
