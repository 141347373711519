import React, { useState } from "react";
import { ReactComponent as Star } from "./star.svg";

const RatingComponent = ({rate, setRate}) => {
  // Function to handle when a star is clicked
  const handleClick = (selectedRating) => {
    setRate(selectedRating);
    // You can perform other actions here, like sending the rating to an API or updating other states
  };

  // Function to render stars
  const renderStars = () => {
    const stars = [];
    // Loop to create five stars
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div
          className={`RatingViewStar flex-c pointer ${
            i <= rate && "yellow-svg"
          }`}
          key={i}
          onClick={() => handleClick(i)}
          onMouseEnter={() => setRate(i)} // Change color when hovering
          onMouseLeave={() => setRate(rate)}
        >
          <Star />
        </div>,
      );
    }
    return stars;
  };
  return (
    <div
      className={`AddRating d-flex gap-2 flex-row-reverse justify-content-center `}
    >
      {renderStars()}
    </div>
  );
};

export default RatingComponent;

// import React from "react";
// import "./AddRating.css";
// import { useState } from "react";

// function AddRating() {
//   const [rateCount, setRateCount] = useState();
//   const five = [1, 2, 3, 4, 5];
//   return (

//       {five.map((item, idx) => (

//       ))}
//     </div>
//   );
// }

// export default AddRating;
