import axios from "axios";
import { basedDomin } from "../../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../../Others/Error";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ExistStatus,
  TapsType,
  changeData,
  mainDataOrderProcessing,
  myOrderLoader,
  typeServices,
} from "../GlopalStateRecoil/AllData";
import { useEffect } from "react";
// Get Car Function

const token = localStorage.getItem("token");

export const useGetOrdersProcessing = () => {
  const navigate = useNavigate();
  const [exist, setExist] = useRecoilState(ExistStatus);
  const [loader, setLoader] = useRecoilState(myOrderLoader);
  const [allData, setAllData] = useRecoilState(mainDataOrderProcessing);
  const changeStatus = useRecoilValue(changeData);
  const typeServes = useRecoilValue(typeServices);
  // Main State Data
  const tapType = useRecoilValue(TapsType);

  const getAllOrdersData = async (url, next) => {
    setLoader(true);
    axios
      .get(
        url || `${basedDomin}/buyer/orders/${tapType}?service=${typeServes}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setAllData((prevState) => ({
          data: next
            ? [...prevState.orders.data, ...data.data.orders.data]
            : data.data.orders.data,
          next: data.data.orders.next_page_url || null,
          prev: data.data.orders.first_page_url,
        }));
        if (data.data.orders.data.length === 0) {
          setExist(false);
        } else {
          setExist(true);
        }
        setLoader(false);
      })
      .catch((error) => {
        ErrorComponent(error, navigate);
        setLoader(false);
      });
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllOrdersData();
  }, [changeStatus, typeServes, tapType]);
  return { exist, loader, allData, getAllOrdersData };
};
// Get Car Function
