import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { basedDomin } from "../Api/basedDomin";

const token = localStorage.getItem("token");

export default function useGetCarLocation(order_id, vendor_id, is_arrived_car) {
  const [location, setLocation] = useState();
  const [locationLoading, setLocationLoading] = useState(true);

  useEffect(() => {
    let timeOut;
    if (order_id && !is_arrived_car) {
      const updateLocation = () => {
        axios
          .get(
            `${basedDomin}/buyer/orders/${order_id}/flatness-location/vendor/${vendor_id}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(({ data }) => {
            setLocation(data.data);
            setLocationLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLocationLoading(false);
          });
      };

      const interval = 10000; // 10 seconds interval

      const updateWithTimeout = () => {
        updateLocation();
        timeOut = setTimeout(updateWithTimeout, interval);
      };

      updateWithTimeout();
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  return { location, locationLoading };
}
