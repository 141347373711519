import React from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Flags from "react-flags-select";
import { ReactComponent as Phone } from "./Assets/phone.svg";
import PhoneNumber from "google-libphonenumber";
export default function PhoneInputComponent({
  errorValidation,
  t,
  phoneNumber,
  setPhoneNumber,
  selectedCountry,
  setCodeCountry,
  setSelectedCountry,
}) {
  // const [phoneNumber, setPhoneNumber] = useState("");
  // Default country code
  // useEffect(() => {
  //   validatePhoneNumberForCountry();
  // }, [phoneNumber, selectedCountry]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);

    // When the phone number changes, extract the country code
    try {
      const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
      const parsedNumber = phoneUtil.parse(value);
      const country = phoneUtil.getRegionCodeForNumber(parsedNumber);
      setCodeCountry(parsedNumber.values_["1"]);
      setSelectedCountry(country);
    } catch (error) {
      // Handle parsing errors or invalid phone number format
      console.log("error");
    }
  };

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
  };

  return (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex align-items-center gap-2 '>
        <Flags
          selected={selectedCountry}
          onSelect={(countryCode) => handleCountryChange(countryCode)}
          showSelectedLabel={true} // Hide selected country label
          showOptionLabel={true} // Hide country name in options
          searchable={true}
          showSecondaryOptionLabel={true}
        />
        <div
          className={`input-sign d-flex gap-2 p-3 r-10  ${
            errorValidation.hasOwnProperty("mobile") && "border border-red"
          }`}
          style={{ width: "75%" }}
        >
          <span className='icon'>
            <Phone />
          </span>
          <input
            className={`full-width border-0 outline-0`}
            type='number'
            id='phone_number'
            value={phoneNumber}
            onChange={(e) => handlePhoneNumberChange(e.target.value)}
            defaultCountry={selectedCountry}
            placeholder={t("register.sign_up.phone")}
          />
        </div>
      </div>
      {errorValidation.hasOwnProperty("mobile") && (
        <span className='text-error fs-14-400 text-center'>
          {errorValidation?.mobile[0]}
        </span>
      )}
    </div>
  );
}
