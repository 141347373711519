import React, { useEffect, useState } from "react";
import "./CostumCheckBox.css";
import "./FilterScrollComponent.css";
import { FiChevronDown } from "react-icons/fi";
import { ReactComponent as Search } from "../Assets/search.svg";
import { useNavigate } from "react-router-dom";
import { getTypeCars } from "./Functions/GetTypesCars";
import { useRecoilState } from "recoil";
import { GetCities } from "./Functions/GetCities";
import {
  citiesFilterOrderVendor,
  typeCarsFilterOrderVendor,
} from "../../../../GlopalStateRecoil/AllData";
import { trans } from "../../../../../../Components/Navbar/Navbar";

function FilterScrollComponent({
  name,
  data,
  radio = false,
  radioType = "",
  handleCheck,
  fieldName,
  setState,
  state,
  setCities,
  setTypeCar,
}) {
  const [open, setOpen] = useState(true);
  // Add/Remove checked Major
  const [filteredData, setFilteredData] = useState(data);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // state type car

  const handleChange = (e) => {
    const query = e.target.value.trim();
    const filtered = data.filter((item) =>
      String(item.label).toLowerCase().includes(query.toLowerCase()),
    );
    setFilteredData(filtered);
  };
  // Add/Remove checked Major
  return (
    <div className='FilterScrollComponent py-3 border-bottom'>
      {/* Nav */}
      <div
        className='nav pointer d-flex align-items-center justify-content-between'
        style={{ width: "218px" }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span>{name}</span>
        <span className={`icon transion-5 ${open && "icon-rotate"}`}>
          <FiChevronDown />
        </span>
      </div>
      {/* cont */}
      <div
        className={`cont transion-5 overflow-hidden ${open && "mt-3"}`}
        style={{ height: open ? "254px" : "0px" }}
      >
        {/* Search */}
        <div className='mb-4 search flex-grow-1 d-flex align-items-center gap-2 p-2 r-10 bg-white border'>
          <span>
            <Search />
          </span>
          <input
            className='border-0 bg-transparent full-width'
            placeholder={trans("cars_market.body.search")}
            type='search'
            onChange={handleChange}
          />
        </div>
        {/* CheckBoxes */}
        <div className='scroll-component'>
          <div className='checkboxes pb-2 d-flex flex-column gap-3'>
            {/* All */}
            {radioType && (
              <label className='container-custom d-flex align-items-center'>
                {"الكل"}
                <input
                  name={radio ? `filter-${radioType}` : ""}
                  type={radio ? "radio" : "checkbox"}
                  onChange={(event) => {
                    setIsAll(event.target.checked);
                    handleCheck(
                      event,
                      fieldName,
                      radio,
                      radioType,
                      event.target.checked,
                    );
                    if (radioType === "region") {
                      setCities([]);
                    } else if (radioType === "company-car") {
                      setTypeCar([]);
                    }
                  }}
                  checked={isAll}
                />
                <span className='checkmark'></span>
              </label>
            )}
            {/* All */}
            {filteredData.map((item) => (
              <FieldCompoennt
                {...{
                  item,
                  radio,
                  fieldName,
                  radioType,
                  handleCheck,
                  isAll,
                  setIsAll,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function FieldCompoennt({
  item,
  radio,
  fieldName,
  radioType,
  handleCheck,
  isAll = false,
  setIsAll,
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isAll) setChecked(false);
  }, [isAll]);
  return (
    <label
      className='container-custom d-flex align-items-center'
      key={item.value}
    >
      {item.label}
      <input
        name={radio ? `filter-${radioType}` : ""}
        value={item.value}
        type={radio ? "radio" : "checkbox"}
        onChange={(event) => {
          setChecked(event.target.checked);
          setIsAll(false);
          handleCheck(event, fieldName, radio, radioType);
        }}
        checked={checked}
      />
      <span className='checkmark'></span>
    </label>
  );
}

export default FilterScrollComponent;
