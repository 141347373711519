import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { basedDomin } from "../Api/basedDomin";
import { UserDefaultLocation } from "../Recoil/location";
import { useRecoilValue } from "recoil";
import useMapLoad from "./useMapLoad";

const token = localStorage.getItem("token");

export default function useSendCarLocation(isFlatness) {
  const userDefaultLocation = useRecoilValue(UserDefaultLocation);

  const [currentLoaction, setCurrentLcation] = useState(userDefaultLocation);
  const isLoaded = useMapLoad();
  const sendTheDefaultLocation = useCallback(async () => {
    if (isFlatness) {
      try {
        const { data } = await axios.post(
          `${basedDomin}/vendor/update-location`,
          { lat: currentLoaction.lat, long: currentLoaction.lng },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentLoaction]);

  useEffect(() => {
    let timeOut;
    let alertLimit = 0;
    if (isFlatness) {
      const updateLocation = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setCurrentLcation(center);
            // Use these coordinates for your map center or other purposes
          },
          () => {
            if (alertLimit === 0) {
              alert(
                "Unable to retrieve your location, please allowed access your location in your browser",
              );
            }
            alertLimit = 1;
          },
        );
      };

      const interval = 10000; // 10 seconds interval

      const updateWithTimeout = () => {
        updateLocation();
        timeOut = setTimeout(updateWithTimeout, interval);
      };

      updateWithTimeout();
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [isFlatness, setCurrentLcation]);
  //send the current loaction each time get updated
  useEffect(() => {
    if (isFlatness) {
      sendTheDefaultLocation();
    }
  }, [sendTheDefaultLocation]);

  return currentLoaction;
}
