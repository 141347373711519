import React, { useCallback, useState, useEffect } from "react";
import "./VendorDetails.css";
import { trans } from "../../Components/Navbar/Navbar";
import TopHead from "./Components/TopHead/TopHead";
import { ReactComponent as LocationIcon } from "./Assets/location.svg";
import { ReactComponent as PhoneIcon } from "./Assets/phone_icon.svg";
import CustomerRating from "./Components/CustomerRating/CustomerRating";
import { basedDomin } from "../../Api/basedDomin";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ErrorComponent } from "../../Others/Error";
import MainLoader from "../../Components/MainLoader/MainLoader";

function VendorDetails() {
  const { userType, vendor_id, order_id } = useParams();
  const [vendorDetails, setVendorDetails] = useState();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const getVendorDetails = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${basedDomin}/buyer/orders/${order_id}/vendor-rates/${vendor_id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVendorDetails({ vendor: data.data.vendor, rates: data.data.rates });
    } catch (e) {
      ErrorComponent(e, navigate);
    } finally {
      setLoading(false);
    }
  }, []);

  const getBuyerDetails = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${basedDomin}/buyer/orders/${order_id}/vendor-rates/${vendor_id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setVendorDetails({ vendor: data.data.vendor, rates: data.data.rates });
    } catch (e) {
      ErrorComponent(e, navigate);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userType === "vendor") getVendorDetails();
    else getBuyerDetails();
  }, [getVendorDetails]);
  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <div className='VendorDetails'>
          <div className='bg-white py-5'>
            <div className='container'>
              <h3 className='fs-24-700 mb-5'>
                {trans("vendor_details.title")}
              </h3>
              <TopHead {...{ vendorDetails, vendor_id }} />
              <OtherDetails {...vendorDetails.vendor} />
            </div>
          </div>

          {/* Bottom */}
          <div className='bg-white py-5 mt-3'>
            <div className='container'>
              <CustomerRating
                {...{
                  data: vendorDetails.rates.data,
                  vendor_id,
                  order_id,
                  setVendorDetails,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const headStyle = {
  color: "#080A1F",
  fontSize: 20,
  fontFamily: "Somar",
  fontWeight: "600",
  wordWrap: "break-word",
};
const textStyle = {
  color: "#6A6A6A",
  fontSize: 16,
  fontFamily: "Somar",
  fontWeight: "400",
  wordWrap: "break-word",
  marginTop: "20px",
};
const otherDetailsStyle = {
  color: "#080A1F",
  fontSize: 14,
  fontFamily: "Somar",
  fontWeight: "400",
  wordWrap: "break-word",
  margin: "0 10px",
};
//     "general_details": "General Details",
// "location": "Location: ",
// "phone": "Mobile number: "
function OtherDetails({ shop_details, street_name, mobile }) {
  return (
    <div className='row' style={{ marginTop: "40px" }}>
      <div className='col-12 col-md-8'>
        <h3 style={headStyle}>{trans("vendor_details.general_details")}</h3>
        <p style={textStyle}>{shop_details}</p>

        <div className='d-flex ' style={{ marginTop: "32px" }}>
          <LocationIcon />
          <div style={otherDetailsStyle}>{`${trans(
            "vendor_details.location",
          )}${street_name}`}</div>
        </div>
        <div className='d-flex' style={{ marginTop: "10px" }}>
          <PhoneIcon />
          <div style={otherDetailsStyle}>{`${trans(
            "vendor_details.phone",
          )}${mobile}`}</div>
        </div>
        {/* <OtherAds /> */}
      </div>
    </div>
  );
}
export default VendorDetails;
