import { useNavigate } from "react-router-dom";
import { ReactComponent as Send } from "../../../../Assets/send.svg";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { ErrorComponent } from "../../../../../../../../Others/Error";
import { trans } from "../../../../../../../../Components/Navbar/Navbar";
import Loader from "../../../../../../../../Components/Loader/Loader";
import { basedDomin } from "../../../../../../../../Api/basedDomin";
import {
  buyerChangeUserId,
  buyerMessagesChanged,
} from "../../../../GlopalStateRecoil/AllData";
import Pusher from "pusher-js";
const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
function Footer({ order_id, vendor_id, setIsWritingNow, isWritingNow }) {
  //listen to other person writitng
  const [BuyerTitmeoutId, setBuyerTitmeoutId] = useState(null);

  //bueyer is wiriting
  useEffect(() => {
    let isThereUpdates = false;
    // Pusher Get New Message
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });
    // writing-now-order-233-user-166-writing-now-order-233-user-166
    const channel = pusher.subscribe(
      `writing-now-order-${order_id || userId.order_id}-user-${
        vendor_id || userId.vendor_id
      }-writing-now-order-${order_id || userId.order_id}-user-${
        vendor_id || userId.vendor_id
      }`,
    );
    channel.bind(
      `writing-now-order-${order_id || userId.order_id}-user-${
        vendor_id || userId.vendor_id
      }`,
      ({ data }) => {
        isThereUpdates = true;
        console.log("the vendor writing now=> ", data);
        setIsWritingNow(true);
      },
    );

    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isWritingNow) {
        console.log("set iswiriting now to false every five seconds");
        setIsWritingNow(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isWritingNow]);
  const handleChange = async () => {
    // Clear any existing timeout
    if (BuyerTitmeoutId) {
      clearTimeout(BuyerTitmeoutId);
    }

    // Set a new timeout that will call the buyerIsWiriting function after  3 seconds
    const newTimeoutId = setTimeout(() => {
      buyerIsWiriting();
    }, 3000);

    // Store the timeout ID
    setBuyerTitmeoutId(newTimeoutId);
  };
  async function buyerIsWiriting() {
    try {
      const { data } = await axios.post(
        `${basedDomin}/buyer/orders/${order_id || userId.order_id}/offers/${
          vendor_id || userId.vendor_id
        }/writing-now`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("data when buyer has writin=> ", data);
    } catch (error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();
  const ref = useRef();
  const userId = useRecoilValue(buyerChangeUserId);
  const [loader, setLoader] = useState(false);
  // Messages Container
  const [Messages, setMessages] = useRecoilState(buyerMessagesChanged);
  // Mesage State
  const [message, sendMessage] = useState("");
  // Onsubmit
  const submit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${basedDomin}/buyer/orders/${order_id || userId.order_id}/offers/${
          vendor_id || userId.vendor_id
        }/send-messages`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessages([data.data, ...Messages]);
      setLoader(false);
      ref.current.value = "";
      sendMessage("");
    } catch (error) {
      ErrorComponent(error, navigate);
      setLoader(false);
    }
  };
  // Onsubmit
  return (
    <div className='send-message border-top p-3'>
      <form onSubmit={submit} className='input-message d-flex'>
        <input
          ref={ref}
          placeholder={trans("harag_message.write_message")}
          className='flex-grow-1 border-0 bg-transparent fs-12-400'
          type='text'
          onChange={(e) => {
            handleChange();
            sendMessage(e.target.value);
          }}
        />
        <button
          type='submit'
          className={`icon border-0 ${loader ? "bg-dark" : "bg-green"}`}
          disabled={loader}
        >
          {loader ? <Loader width={"25px"} /> : <Send />}
        </button>
      </form>
    </div>
  );
}

export default Footer;
