import React from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import i18n from "../../i18n";
import { UserDefaultLocation } from "../../Recoil/location";
import { useRecoilValue } from "recoil";
const MapComponent = ({ currently, setCurrently }) => {
  // Api Key
  const YOUR_API_KEY = "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo";
  // Api Key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: YOUR_API_KEY,
    language: i18n.language,
  });
  // style
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  // Current
  const onMapClickCurrent = (e) => {
    setCurrently({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const userDefaultLocation = useRecoilValue(UserDefaultLocation);

  return (
    <>
      {isLoaded && userDefaultLocation ? (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={8}
          center={currently.lat > 0 ? currently : userDefaultLocation}
          onClick={onMapClickCurrent}
        >
          <MarkerF position={currently} />
        </GoogleMap>
      ) : null}
    </>
  );
};

export default MapComponent;
