import { atom } from "recoil";

export const notificationsTypeState = atom({
  key: "NotificationsTypeState",
  default: {
    data: [],
    first: null,
    next: null,
  },
});
