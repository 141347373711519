import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import { ReactComponent as User } from "../../Assets/user.svg";

export default function ContactBox({
  connect,
  openChat,
  setLoading,
  token,
  setStatus,
}) {
  const getChat = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${basedDomin}/shop/chat/ads/${connect.ad_id}/user/${connect.user_id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      openChat(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setStatus(true);
  };

  return (
    <div
      onClick={getChat}
      className='link-message d-flex pointer justify-content-between transion-5'
    >
      <div className='d-flex align-items-center gap-3'>
        <div className='image flex-c'>
          <User />
        </div>
        <div className='text'>
          <h4 className='mt-1'>{connect.connect_name}</h4>
          <p className='mt-2'>{connect.message}</p>
        </div>
      </div>
      <div className='time'>{connect.time}</div>
    </div>
  );
}
