import { atom } from "recoil";

export const UserDefaultLocation = atom({
  key: "UserDefaultLocation",
  default: {
    lat: 23.616151837565937,
    lng: 44.869853511853336,
  },
});
export const IsFlatness = atom({
  key: "IsFlatness",
  default: {
    isFlastness: false,
    is_moved: false,
    is_arrived: false,
  },
});
