import { Navigate } from "react-router-dom";

const user = JSON.parse(localStorage.getItem("user"));

export function ProtectedRoute({ children }) {
  if (!user) {
    return <Navigate to='/register/sign-in' />;
  } else {
    return children;
  }
}

export function ProtectedRouteToSignIn({ children }) {
  if (user) {
    if (user.type === "vendor") {
      return <Navigate to='/vendor' />;
    } else {
      return <Navigate to='/' />;
    }
  } else {
    return children;
  }
}
