import React from "react";
import { trans } from "../../../../Components/Navbar/Navbar";

import { useState, useEffect } from "react";

import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import Loader from "../../../../Components/Loader/Loader";
import NothingLottie from "../../../../Components/NothingLottie/NothingLottie";
import ChatScreen from "./ChatScreen";

function HaragMessage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const [Messages, setMessages] = useState({
    data: [],
    next: null,
    prev: null,
  });
  const fetchData = async (url, next) => {
    try {
      const response = await axios.get(
        url || `${basedDomin}/shop/chat/ads/users-chat`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessages((prevState) => ({
        data: next
          ? [...prevState.data, ...response.data.data.data]
          : response.data.data.data,
        next: response.data.data.next_page_url,
        prev: response.data.data.first_page_url,
      }));
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div className='flex-grow-1 flex-c '>
          <Loader width={"150px"} />
        </div>
      ) : Messages.data.length > 0 ? (
        <ChatScreen messages={Messages} fetchConnectsData={fetchData} />
      ) : (
        <div className='body d-flex flex-column align-items-center justify-content-center gap-4 full-height'>
          <NothingLottie width={"300px"} />
          <p className='fs-20-600'>{trans("harag_message.harag_no_message")}</p>
        </div>
      )}
    </>
  );
}

export default HaragMessage;
