
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import i18n from "../i18n";
export default function useMapLoad() {
     // Api Key
  const YOUR_API_KEY = "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo";
  // Api Key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: YOUR_API_KEY,
    language: i18n.language,
  });

  return isLoaded
}