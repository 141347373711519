import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import User from "./Components/User";
import { IoClose } from "react-icons/io5";
import { useRecoilState, useRecoilValue } from "recoil";
import NotUser from "./Components/NotUser/NotUser";
import axios from "axios";
import { useState } from "react";
import ScrollarComponent from "../../../../../../Components/ScrollarComponent/ScrollarComponent";
import { trans } from "../../../../../../Components/Navbar/Navbar";
import { basedDomin } from "../../../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../../../Others/Error";
import Loader from "../../../../../../Components/Loader/Loader";
import {
  blockedUserChangeStatus,
  buyerMessagesChanged,
  buyerOpenListUserMessages,
  filterUserListType,
} from "../../GlopalStateRecoil/AllData";
import ControllData from "../../../../../../Components/ControllData";

function ListedUsers() {
  //
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  //
  // State
  const [users, setUsers] = useState({
    data: [],
    next_spare_parts: null,
    prev_spare_parts: null,
    next_maintenance_services: null,
    prev_maintenance_services: null,
    next_flatness: null,
    prev_flatness: null,
  });
  const [exist, setExist] = useState(false);
  const [loader, setLoader] = useState(false);

  const isMessagesChanged = useRecoilValue(buyerMessagesChanged);
  // State
  // State Filter User Blocked
  const filterBlockedList = blockedUserChangeStatus;
  // State Filter User Blocked
  // State List User Type
  const [filterTypesList, setFilterTypesList] =
    useRecoilState(filterUserListType);
  // State List User Type
  // State Open List User
  const [openListUser, setOpenListUser] = useRecoilState(
    buyerOpenListUserMessages,
  );
  // Sort List User
  function compareDates(a, b) {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  }
  // State Open List User
  // Get User Chat
  const getUsersChats = async (
    sparePartsUrl,
    maintenanceServicesUrl,
    flatnessUrl,
    next,
  ) => {
    try {
      let mergedData = [];
      const responses = await Promise.all([
        axios
          .get(
            sparePartsUrl ||
              `${basedDomin}/buyer/orders/all-offers/spare_parts`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(({ data }) => {

            mergedData = [...mergedData, ...data.data.data];
            setUsers({
              ...users,
              next_spare_parts: data.data.next_page_url,
              prev_spare_parts: data.data.prev_page_url,
            });
          }),
        axios
          .get(
            maintenanceServicesUrl ||
              `${basedDomin}/buyer/orders/all-offers/maintenance_services`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(({ data }) => {

            mergedData = [...mergedData, ...data.data.data];
            setUsers({
              ...users,
              next_maintenance_services: data.data.next_page_url,
              prev_maintenance_services: data.data.prev_page_url,
            });
          }),
        axios
          .get(
            flatnessUrl || `${basedDomin}/buyer/orders/all-offers/flatness`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(({ data }) => {
            mergedData = [...mergedData, ...data.data.data];
            setUsers({
              ...users,
              next_flatness: data.data.next_page_url,
              prev_flatness: data.data.prev_page_url,
            });
          }),
      ]);

      //const mergedData = responses.flatMap((data) => data.data.data);
      setUsers((prevState) => ({
        ...prevState,
        data: next
          ? [
              ...prevState.data,
              ...mergedData.filter((user) => !user.vendor.is_vendor_block),
            ]
          : mergedData.filter((user) => !user.vendor.is_vendor_block),
      }));

      if (mergedData.length === 0) {
        setExist(false);
      } else {
        setExist(true);
      }
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkIfThereNextPage = () => {
    //if there some url link
    return Object.keys(users)
      .filter((k) => k !== "data" && !k.includes("prev"))
      .map((k) => users[k])
      .some((v) => !!v);
  };
  const checkIfTherePrevPage = () => {
    //if there some url link
    return Object.keys(users)
      .filter((k) => k !== "data" && !k.includes("next"))
      .map((k) => users[k])
      .some((v) => !!v);
  };
  //inital cahts
  const getUserChat = useCallback(async () => {
    setLoader(true);
    await getUsersChats();
    setLoader(false);
  }, []);
  // UseEffect
  useEffect(() => {
    if (users.data.length > 0) return;
    getUserChat();
  }, [getUserChat, users.data.length]);
  //change the user whenever the messages changed
  useEffect(() => {
    if (isMessagesChanged.length === 0) return;
    getUsersChats();
  }, [isMessagesChanged.length]);

  // Sort List Users
  return (
    <div
      className='all-message full-height d-flex flex-column gap-4'
      style={{ overflowY: "auto" }}
    >
      <div className='top d-flex align-items-center justify-content-between'>
        <span className='message-count fs-20-600'>{`${trans(
          "requestes_message.all_requestes",
        )}`}</span>
        <span
          className='flex-c pointer fs-24-700'
          onClick={() => {
            setOpenListUser(false);
          }}
        >
          <IoClose />
        </span>
      </div>
      {/* Message Tap */}
      <div className='side flex-grow-1'>
        {loader ? (
          <div className=' full-height flex-c'>
            <Loader width={"150px"} />
          </div>
        ) : exist ? (
          <div className='d-flex flex-column gap-2'>
            {users.data.sort(compareDates).map((item, idx) => {
              return <User {...item} key={idx} />;
            })}

            <ControllData
              {...{
                conditionToMore: checkIfThereNextPage(),
                conditionToLess:
                  !checkIfThereNextPage() && checkIfTherePrevPage(),
                onMore: () =>
                  getUsersChats(
                    users.next_spare_parts,
                    users.next_maintenance_services,
                    users.next_flatness,
                    true,
                  ),
                onLess: () =>
                  getUsersChats(
                    users.prev_spare_parts,
                    users.prev_maintenance_services,
                    users.prev_flatness,
                  ),
              }}
            />
          </div>
        ) : (
          <div className=' full-height flex-c'>
            <NotUser />
          </div>
        )}
      </div>
    </div>
  );
}

export default ListedUsers;
