import React from "react";
import { ReactComponent as Star } from "./star.svg";
import "./RatingView.css";

function RatingView({ rates, fullStars }) {
  // Function to render stars based on the average rating
  const renderStars = () => {
    let averageRating;
    if (rates && rates.length > 0) {
      const totalRatings = rates.length;
      averageRating =
        rates.reduce((acc, curr) => acc + curr.rate, 0) / totalRatings;
    } else {
      averageRating = fullStars; // If ratings array not provided, use the fullStars prop directly
    }
    const stars = [];
    // Full stars
    for (let i = 0; i < Math.floor(averageRating); i++) {
      stars.push(
        <div key={i} className={`RatingViewStar flex-c ${"yellow-svg"}`}>
          <Star />
        </div>,
      ); // Unicode for a filled star
    }
    // Partial star if needed
    // if (averageRating % 1 !== 0) {
    //   stars.push(
    //     <span key='partial' style={{ width: "50%", overflow: "hidden" }}>
    //       &#9733;
    //     </span>,
    //   ); // Unicode for half-filled star
    // }
    // Empty stars
    const emptyStars = 5 - Math.ceil(averageRating);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <div key={`empty-${i}`} className={`RatingViewStar flex-c`}>
          <Star />
        </div>,
      ); // Unicode for an empty star
    }
    return stars;
  };
  return (
    <div
      className={`RatingView d-flex gap-2 flex-row-reverse justify-content-end `}
    >
      {renderStars()}
    </div>
  );
}

export default RatingView;
