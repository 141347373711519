export function formatDate(dateString, language) {
    const date = new Date(dateString);
  
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC',
      hourCycle: 'h12',
      weekday: 'long',
    };
  
    const formatter = new Intl.DateTimeFormat(language, options);
    const formattedDate = formatter.format(date);
  
    if (language === 'en') {
      // To change the direction from ltr to rtl for English
      return formattedDate.split('').reverse().join('');
    }
  
    return formattedDate;
  }
  