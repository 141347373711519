import { useEffect } from "react";
import Echo from "laravel-echo";
import { basedDomin } from "../Api/basedDomin";
import { useRecoilState } from "recoil";
import { ActiveUsers } from "../Recoil/ActiveUsers";

const token = localStorage.getItem("token");

export default function useGetActiveUsers() {
  const [onlineUsers, setOnlineUsers] = useRecoilState(ActiveUsers);

  useEffect(() => {
    const initializeEcho = () => {
      return new Echo({
        broadcaster: "pusher",
        key: "e1a99b18f88e0adba1aa",
        cluster: "eu",
        encrypted: true,
        forceTLS: true,
        authEndpoint: `${basedDomin}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    };

    const echo = initializeEcho();
    const presenceChannel = echo.join("online");

    const handleHereEvent = (users) => {
      setOnlineUsers(users);
    };

    const handleJoiningEvent = (user) => {
      setOnlineUsers((prevUsers) => [...prevUsers, user]);
    };

    const handleLeavingEvent = (user) => {
      setOnlineUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
    };

    presenceChannel.here(handleHereEvent);
    presenceChannel.joining(handleJoiningEvent);
    presenceChannel.leaving(handleLeavingEvent);

    return () => {
      echo.disconnect();
    };
  }, [setOnlineUsers]);

  return onlineUsers;
}
