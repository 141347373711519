import { useRecoilValue } from "recoil";
import { notificationsTypeState } from "../../Recoil/Notifications";
import { ReactComponent as NotificationIcon } from "./Assets/noti-icon.svg";
import "./style.css";
import useGetNotifications from "../../hooks/useGetNotifications";
import { Link } from "react-router-dom";
export default function Notifications({ menuNotiClic }) {
  const notifications = useRecoilValue(notificationsTypeState);
  const getMoreNotifications = useGetNotifications();
  const checkNotifcationType = (notification) => {
    switch (notification.model) {
      case "order":
        return (
          <NotifationBOx
            key={notification.id}
            item={notification}
            path={`/vendor/${notification.params.order_id}`}
          />
        );

      case "deal":
        return <NotifationBOx key={notification.id} item={notification} />;

      case "message":
        return <NotifationBOx key={notification.id} item={notification} />;

      case "welcome":
        return <NotifationBOx key={notification.id} item={notification} />;
      case "offer":
        if (notification.params.vendor_id) {
          return (
            <NotifationBOx
              key={notification.id}
              item={notification}
              path={`/my-profile/requestes-message/${notification.params.order_id}/${notification.params.vendor_id}`}
            />
          );
        } else {
          return (
            <NotifationBOx
              key={notification.id}
              item={notification}
              path={`/vendor/${notification.params.order_id}`}
            />
          );
        }

      default:
        return <NotifationBOx key={notification.id} item={notification} />;
    }
  };

  return (
    <div
      ref={menuNotiClic}
      className={`position-absolute r-10 p-2 text-white MenueUser d-flex flex-column gap-2
      }`}
      style={{ minWidth: "450px", maxHeight: "700px", overflow: "auto" }}
      data-aos='fade-left'
      data-aos-duration='500'
    >
      <>
        <div
          className='p-2 text-black'
          data-aos='fade-left'
          data-aos-duration={`100`}
        >
          {notifications.data.map((item) => checkNotifcationType(item))}
        </div>
        {notifications.next && (
          <div
            className='text-black-50 text-center'
            onClick={(e) => {
              e.stopPropagation();
              getMoreNotifications(notifications.next, true);
            }}
          >
            See More
          </div>
        )}
        {!notifications.next && notifications.data.length > 15 && (
          <div
            className='text-black-50 text-center'
            onClick={(e) => {
              e.stopPropagation();
              getMoreNotifications(notifications.first);
            }}
          >
            See Less
          </div>
        )}
      </>
    </div>
  );
}

function NotifationBOx({ item, path }) {
  return (
    <Link
      to={path}
      style={{
        minWidth: "400px",
        padding: "10px 8px",
      }}
      className='notification d-flex align-items-center text-black'
    >
      <NotificationIcon />
      <p style={{ margin: "0 10px" }}>{item.content}</p>
    </Link>
  );
}

/* onTap: () {
  if (notification.userType == UserType.buyer) {
    if (notification.model == NotificationType.offer) {
      Get.toNamed(
        Routes.repliesChat,
        arguments: {
          'vendor_id': notification.notificationParams?.vendorId,
          'order_id': notification.notificationParams?.orderId,
        },
      );
    }
  } else {



    switch (notification.model) {
      case NotificationType.order:
      case NotificationType.offer:
        if (notification.notificationParams?.serviceType ==
            ServiceType.spareParts) {
          Get.toNamed(
            Routes.sparePartsRequestVendorView,
            arguments: {
              'order_id': notification.notificationParams?.orderId
            },
          );
        } else {
          Get.toNamed(
            Routes.otherRequestVendorView,
            arguments: {
              "order_id": notification.notificationParams?.orderId
            },
          );
        }
        break;
      default:
    }
  }
} */
