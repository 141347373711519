import React, { useState } from "react";
import "./Filter.css";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  citiesFilterOrderVendor,
  openFilterOrderVendor,
  typeCarsFilterOrderVendor,
} from "../../../GlopalStateRecoil/AllData";
import { trans } from "../../../../../Components/Navbar/Navbar";
import FilterScrollComponent from "./FilterScrollComponent/FilterScrollComponent";
import {
  brandsCarsFromApi,
  repeatCountries,
} from "../../../../../Recoil/All/GeneralData";
import { generateYears } from "../../../../../Recoil/All/GenerateYears";
import { getMultiTypeCars } from "./FilterScrollComponent/Functions/GetTypesCars";
import { GetCities } from "./FilterScrollComponent/Functions/GetCities";
import { useNavigate } from "react-router-dom";

function Filter({ filterData, setFilterData, getOrdersFauction }) {
  const menuFilterIcon = useRecoilValue(openFilterOrderVendor);
  // state type car
  const [typeCar, setTypeCar] = useRecoilState(typeCarsFilterOrderVendor);
  // state type car
  // state type car
  const [citiesOption, setCities] = useRecoilState(citiesFilterOrderVendor);

  const navigate = useNavigate();

  const handleCheck = (event, name, radio, radioType, isAll) => {
    if (isAll) {
      setFilterData({ ...filterData, [name]: [] });
    } else {
      if (radio) {
        if (radioType === "region") {
          setFilterData({
            ...filterData,
            city: [],
            [name]: [event.target.value],
          });
          GetCities(event.target.value, setCities, navigate);
        }
      } else {
        var updatedList = [...filterData[name]];
        updatedList = [...filterData[name], event.target.value];
        if (event.target.checked) {
          if (radioType === "company-car") {
            setFilterData({ ...filterData, car: [] });
            getMultiTypeCars(event.target.value, typeCar, setTypeCar, navigate);
          }
        } else {
          if (radioType === "company-car") {
            setTypeCar(
              typeCar.filter((typ) => typ.companyId !== event.target.value),
            );
          }
          updatedList = filterData[name].filter(
            (id) => id !== event.target.value,
          );
        }

        setFilterData({ ...filterData, [name]: updatedList });
      }
    }
  };
  // Satates Data
  const brandsCarOption = useRecoilValue(brandsCarsFromApi);
  const typeCars = useRecoilValue(typeCarsFilterOrderVendor);
  const countriesState = useRecoilValue(repeatCountries);
  const cities = useRecoilValue(citiesFilterOrderVendor);
  const years = useRecoilValue(generateYears);
  // Function OnSubmit
  const onsubmit = (e) => {
    e.preventDefault();
    getOrdersFauction();
  };
  return (
    <div
      className='FilterVendor box-sh overflow-hidden bg-white'
      style={menuFilterIcon ? { width: "250px" } : { width: "0px" }}
    >
      <div className='p-3 window-scroll'>
        <div
          className='d-flex justify-content-between align-items-center mb-4'
          style={{ width: "218px" }}
        >
          <h4>{trans("cars_market.filter.filter")}</h4>
          <form onSubmit={onsubmit}>
            <button
              type='submit'
              className='confirm-filter border-0 bg-green text-white flex-c'
            >
              {trans("cars_market.filter.confirm")}
            </button>
          </form>
        </div>
        <FilterScrollComponent
          name={trans("cars_market.filter.company")}
          state={filterData.companyCar}
          setState={setFilterData}
          data={brandsCarOption}
          radio={false}
          radioType='company-car'
          fieldName='companyCar'
          handleCheck={handleCheck}
          setTypeCar={setTypeCar}
        />
        {typeCars.length > 0 && (
          <FilterScrollComponent
            name={trans("cars_market.filter.car")}
            state={filterData.car}
            setState={setFilterData}
            data={typeCars}
            handleCheck={handleCheck}
            fieldName='car'
          />
        )}
        <FilterScrollComponent
          name={trans("cars_market.filter.region")}
          state={filterData.country}
          setState={setFilterData}
          data={countriesState}
          radio={true}
          radioType='region'
          handleCheck={handleCheck}
          fieldName='country'
          setCities={setCities}
        />
        {cities.length > 0 && (
          <FilterScrollComponent
            name={trans("cars_market.filter.city")}
            state={filterData.city}
            setState={setFilterData}
            data={cities}
            handleCheck={handleCheck}
            fieldName='city'
          />
        )}
        <FilterScrollComponent
          name={trans("cars_market.filter.manufacturing_year")}
          state={filterData.modelYears}
          setState={setFilterData}
          data={years}
          handleCheck={handleCheck}
          fieldName='modelYears'
        />
      </div>
    </div>
  );
}

export default Filter;
