import axios from "axios";
import { useState, useEffect } from "react";
import { basedDomin } from "../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../Others/Error";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { blockedUserChangeStatusVendor } from "../../../../Components/Messages/GlopalStateRecoil/AllData";

const token = localStorage.getItem("token");

export default function useGetUsersList() {
  const [users, setUsers] = useState({
    data: [],
    next: null,
    prev: null,
  });
  const [exist, setExist] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const getUserChat = (url, next) => {
    setLoader(true);
    axios
      .post(
        url || `${basedDomin}/vendor/orders/offers`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setUsers((prevState) => ({
          data: next
            ? [
                ...prevState.data,
                ...data.data.data.filter((user) => !user.buyer.is_buyer_block),
              ]
            : data.data.data.filter((user) => !user.buyer.is_buyer_block),
          next: data.data.next_page_url || null,
          prev: data.data.first_page_url,
        }));
        if (data.data.data.length === 0) {
          setExist(false);
        } else {
          setExist(true);
        }
        setLoader(false);
      })
      .catch((error) => {
        ErrorComponent(error, navigate);
      });
  };
  // UseEffect

  return { getUserChat, users, exist, loader };
}
