import React, { useEffect, useState } from "react";
import "./Order.css";
import Filter from "./Components/Filter/Filter";
import Body from "./Components/Body/Body";
import { openFilterOrderVendor } from "../GlopalStateRecoil/AllData";
import { useRecoilState, useRecoilValue } from "recoil";
import { ErrorComponent } from "../../../Others/Error";
import { basedDomin } from "../../../Api/basedDomin";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Messages from "../../../Components/Messages/Messages";
import TheOrders from "./Components/Body/Components/TheOrders/TheOrders";
import { WindowChangeView } from "./Components/Body/GlopalStateRecoil/AllData";
import { blockedUserChangeStatusVendor } from "../../../Components/Messages/GlopalStateRecoil/AllData";
import useGetUsersList from "./hooks/useGetUsersList";
import useGetActiveUsers from "../../../hooks/useGetActiveUsers";

function Orders({ order_id }) {
  const menuFilterIcon = useRecoilValue(openFilterOrderVendor);
  const [orders, setOrders] = useState({
    total: "",
    next: null,
    prev: null,
    data: [],
  });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [exist, setExist] = useState(false);
  const activeUsers = useGetActiveUsers();
  //
  // State of list users

  const [window, setWindow] = useRecoilState(WindowChangeView);

  useEffect(() => {
    if (order_id) setWindow("myOffers");
  }, [order_id]);
  const usersListData = useGetUsersList();
  // State Filter User Blocked

  //filterData:
  const [filterData, setFilterData] = useState({
    companyCar: [],
    car: [],
    country: [],
    city: [],
    modelYears: [],
  });

  const getOrdersFauction = (url, next) => {
    axios
      .post(
        url || `${basedDomin}/vendor/orders`,
        {
          brands_ids: filterData.companyCar,
          types_ids: filterData.car,
          countries: filterData.country,
          cities: filterData.city,
          models_years: filterData.modelYears,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        setOrders((prevState) => ({
          next: data.data.orders.next_page_url,
          prev: data.data.orders.first_page_url,
          data: next
            ? [...prevState.data, ...data.data.orders.data]
            : data.data.orders.data,
        }));
        if (data.data.orders.data.length > 0) {
          setExist(true);
        } else {
          setExist(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        ErrorComponent(error, navigate);
      });
  };
  useEffect(() => {
    usersListData.getUserChat();
    getOrdersFauction();
  }, []);

  return (
    <div className={`OrdersVendor mt-4 mt-md-5 position-relative`}>
      <div className='container'>
        <div className={`d-flex ${menuFilterIcon && "gap-4"} content`}>
          <Filter {...{ filterData, setFilterData, getOrdersFauction }} />
          <div className='body-padding flex-grow-1 full-hieght'>
            <Body {...{ setWindow, window }}>
              {window === "theOrders" ? (
                <TheOrders {...{ orders, loader, exist, getOrdersFauction }} />
              ) : (
                <Messages
                  {...{
                    order_id,
                    usersListData: {
                      users: usersListData.users,
                      exist: usersListData.exist,
                      loader: usersListData.loader,
                      getUsers: usersListData.getUserChat,
                    },
                  }}
                />
              )}
            </Body>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
