import React, { useEffect, useState } from "react";
import { ReactComponent as Shape } from "./Assets/recoverCode.svg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
// import "./Inputs.css";
import { basedDomin } from "../../Api/basedDomin";
import axios from "axios";
import { ErrorComponent, SuccsesComponent } from "../../Others/Error";

function VerifyEmail() {
  const language = localStorage.getItem("i18nextLng");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const navigat = useNavigate();
  const { t, i18n } = useTranslation();

  // Validation State
  const [errorValidation, setErrorValidation] = useState();
  // Validation State
  const [code, setCode] = useState();
  // Validation Mobile Number
  const [seconds, setSeconds] = useState(59);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    let interval;

    // Reset timer when component mounts or when user clicks the button
    const resetTimer = () => {
      if (seconds === 59) setSeconds(58);
      setResendDisabled(true);
    };

    resetTimer(); // Initial setup

    // Start the timer interval
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else if (seconds === 0) {
        setResendDisabled(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const onsubmit = async (e) => {
    e.preventDefault();
    try {
      //send the code
      const { data } = await axios.post(
        `${basedDomin}/public/profile/email/active`,
        { code },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      SuccsesComponent(data.message);
      //sreset the user data in LocalStorage
      const updatedUserData = { ...user, email_verified_at: true };
      localStorage.setItem("user", JSON.stringify(updatedUserData));
      navigat(
        user.type === "vendor" ? "/vendor/profile/setting" : "/my-profile",
      );
    } catch (err) {
      ErrorComponent(err, navigat, setErrorValidation);
    }
  };
  //ask to resend the code to Email
  async function resendCodeToEmail() {
    try {
      //send the code
      const { data } = await axios.post(
        `${basedDomin}/public/profile/email/resend-code`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      SuccsesComponent(data.message);
    } catch (err) {
      ErrorComponent(err, navigat, setErrorValidation);
    }
  }
  // function Submit
  return (
    <div
      className='SendCode w-full my-3 bg-white py-4 py-md-5 px-3 px-md-5 d-flex flex-column gap-4 align-items-center'
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <div id='recaptcha-container'></div>
      <div className='logo'>
        <Shape />
      </div>
      <h3 className='fs-24-700'>{t("register.recover_code.title")}</h3>
      <p>{t("register.recover_code.desc")}</p>
      {/* Form */}
      <form className='full-width d-flex flex-column gap-4' onSubmit={onsubmit}>
        {/* Virify */}
        <div className='Inputs'>
          <VerificationInput
            length={6}
            passwordMode={true}
            validChars='A-Za-z0-9'
            classNames={{
              container: "container",
              character: `character ${errorValidation && "red"}`,
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
            onChange={(e) => {
              setCode(e);
            }}
          />
        </div>
        {/* Submit */}
        <button className='border-0 bg-green text-white button-font r-07 py-3 px-4'>
          {t("register.recover_code.confirm")}
        </button>
      </form>
      <div className='no-have d-flex align-items-center gap-1 '>
        <span>{t("register.recover_code.not_reseave")}</span>
        <button
          disabled={resendDisabled}
          style={{
            cursor: resendDisabled ? "not-allowed" : "pointer",
            border: "none",
            background: "transparent",
          }}
          onClick={resendCodeToEmail}
        >
          {seconds
            ? `${t("register.recover_code.resend")} ${seconds}`
            : t("register.recover_code.able_to_send")}
        </button>
      </div>
    </div>
  );
}

export default VerifyEmail;
