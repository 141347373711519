import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import ScrollToTop from "./ScrollToTopRouter/ScrollToTopRouter";
import { useRecoilValue } from "recoil";
import { LoaderState } from "./Recoil/All/Loader";
import MainLoader from "./Components/MainLoader/MainLoader";
import useGetGenralData from "./hooks/useGetGeneralData";

const language = localStorage.getItem("i18nextLng");
if (!language) {
  localStorage.setItem("i18nextLng", "ar");
}

function App() {
  const loader = useRecoilValue(LoaderState);
  // States Data
  const getGeneralData = useGetGenralData();

  return (
    <>
      {loader && <MainLoader />}
      <div
        className='App position-relative'
        style={{ minHeight: "100vh" }}
        dir={language === "en" ? "ltr" : "rtl"}
      >
        <ScrollToTop />
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}

export default App;
