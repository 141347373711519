import React, { useState, useEffect } from "react";
import "./OtherImages.css";
import { trans } from "../../../../Components/Navbar/Navbar";
import Image1 from "./Assets/1.png";
import Image2 from "./Assets/2.png";
import Image3 from "./Assets/3.png";
import { ReactComponent as Chat } from "./Assets/chat.svg";
import Popup from "../../../../Components/Popup/Popup";
import MessageComponent from "../Image/Components/Message/Message";

function OtherImages({ data }) {
  const imageArr = [Image1, Image2, Image3];
  const [open, setOpen] = useState({
    status: false,
    text: ``,
  });
  useEffect(() => {
    setOpen({
      ...open,
      text: `${trans("ads_details.popup_message.title")}${
        data?.ad?.owner?.name
      }`,
    });
  }, [data?.ad?.owner?.name]);

  return (
    <div className='OtherImages'>
      {/* <h3 className="title-dev mb-4">{trans("ads_details.other_image")}</h3>
      <div className="images d-flex flex-column gap-3">
        {imageArr.map((img, idx) => (
          <div
            className="img bg-image r-10"
            key={idx}
            style={{ backgroundImage: `url(${img})`}}
          ></div>
        ))}
      </div> */}
      {/* Button Conect with buyr */}

      <button
        onClick={() => setOpen({ ...open, status: true })}
        className='r-10 full-width d-block border-0 bg-main text-white d-flex align-items-center gap-3 justify-content-center'
      >
        <span>
          <Chat />
        </span>
        <span>{trans("ads_details.contact_to_vendor")}</span>
      </button>

      {open.status && (
        <Popup state={open} setState={setOpen}>
          <MessageComponent data={data} />
        </Popup>
      )}
    </div>
  );
}

export default OtherImages;
