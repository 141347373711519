import { useEffect, useState } from "react";
import { UserDefaultLocation } from "../Recoil/location";
import { useRecoilState } from "recoil";
import useMapLoad from "./useMapLoad";

export default function useGetDefaultLocattion() {
  const isLoaded = useMapLoad();

  const [userDefaultLocation, setUserDefaultLocation] =
    useRecoilState(UserDefaultLocation);

  useEffect(() => {
    // let alertLimit = 0;
    // if (isLoaded) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const center = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //       setUserDefaultLocation(center);
    //       // Use these coordinates for your map center or other purposes
    //     },
    //     () => {
    //       if (alertLimit === 0) {
    //         alert(
    //           "Unable to retrieve your location, please allowed access your location in your browser",
    //         );
    //       }
    //       alertLimit = 1;
    //     },
    //   );
    // }
  }, [isLoaded]);
}
