import { atom } from "recoil";

export const LoaderState = atom({
  key: "LoaderState",
  default: true,
});
export const LoaderStateEdit = atom({
  key: "LoaderStateEdit",
  default: false,
});
