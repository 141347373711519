import React, { useCallback, useState, useEffect } from "react";
import "./OrderFlat.css";
import { ReactComponent as Truck } from "./Assets/truck.svg";
import { ReactComponent as SmallTruck } from "./Assets/small_truck.svg";
import { ReactComponent as CarMove } from "./Assets/car_moved.svg";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import { trans } from "../../Components/Navbar/Navbar";
import axios from "axios";
import { basedDomin } from "../../Api/basedDomin";
import { ErrorComponent } from "../../Others/Error";
import { useNavigate, useParams } from "react-router-dom";
import MainLoader from "../../Components/MainLoader/MainLoader";
import i18n from "../../i18n";
import { formatDate } from "./utils_funcitons";
import CarArrived from "./Icons/CarArrived";
import useGetCarLocation from "../../hooks/useGetCarLocation";

//style
const detailHead = {
  color: "#6A6A6A",
  fontSize: 14,
  fontFamily: "Somar",
  fontWeight: "400",
  wordWrap: "break-word",
  marginBottom: "12px",
};
const detailText = {
  color: "#080A1F",
  fontSize: 14,
  fontFamily: "Somar",
  fontWeight: "500",
  wordWrap: "break-word",
};

function OrderFlat() {
  const token = localStorage.getItem("token");
  // get the Order Datail =>
  const prams = useParams();
  const navigate = useNavigate();
  const [loader, setLoder] = useState(true);

  const [data, setData] = useState();

  const getTheOrderDetailById = useCallback(async () => {
    if (prams?.order_id) {
      axios
        .get(
          `${basedDomin}/buyer/orders/${prams?.order_id}/offers/${prams?.vendor_id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(({ data }) => {
          setData(data.data);
          setLoder(false);
        })
        .catch((error) => {
          console.log(error);
          //ErrorComponent(error, navigate);
        });
    }
  }, []);

  // const getTheLocationDetailById = useCallback(async () => {
  //   if (prams.order_id) {
  //     setLocationLoading(true);
  //     axios
  //       .get(
  //         `${basedDomin}/buyer/orders/${prams.order_id}/flatness-location/vendor/${prams.vendor_id}`,
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         },
  //       )
  //       .then(({ data }) => {
  //         setLocation(data.data);
  //         setLocationLoading(false);
  //       })
  //       .catch((error) => {
  //         setLocationLoading(false);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    getTheOrderDetailById();
  }, [getTheOrderDetailById]);
  // Fuction Submit
  const is_arrived_car = data?.is_arrived_car ? data?.is_arrived_car : "";
  const { location, locationLoading } = useGetCarLocation(
    prams.order_id,
    prams.vendor_id,
    is_arrived_car,
  );
  return (
    <>
      {loader ? (
        <MainLoader />
      ) : (
        <div className='OrderFlat'>
          <div className='container'>
            <div className='row g-4 g-lg-0'>
              <div className='col-12 col-lg-4'>
                <div className='order-flat pt-5 pb-0 pb-lg-5'>
                  {/* Head */}
                  <div className='head' style={{ marginBottom: "85px" }}>
                    <h3 className='fs-24-700'>
                      {trans("flow_flatness.general_details")}
                    </h3>
                  </div>
                  <Truck />
                  <div>
                    {/* Details */}
                    <div
                      className='row'
                      style={{ marginTop: "65px", marginBottom: "40px" }}
                    >
                      <div className='col-6'>
                        <div style={detailHead}>
                          {trans("flow_flatness.order_num")}
                        </div>
                        <div style={detailText}>{data.order_num || "1236"}</div>
                      </div>
                      <div className='col-6'>
                        <div style={detailHead}>
                          {trans("flow_flatness.order_date_and_time")}
                        </div>
                        <div style={detailText}>
                          {formatDate(data.created_at, i18n.language)}
                        </div>
                      </div>
                    </div>
                    {/* PROGRESS */}
                    <Progress {...data} />
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-8'>
                <div className='map box-sh'>
                  {locationLoading ? (
                    <>Loading map...</>
                  ) : location?.lat && location?.long ? (
                    <MapComponent
                      {...{
                        currently: {
                          lat: Number(location.lat),
                          lng: Number(location.long),
                        },
                        interfaceLocation: {
                          lat: Number(data.order_details.to_lat),
                          lng: Number(data.order_details.to_long),
                        },
                      }}
                    />
                  ) : (
                    <>{trans("flow_flatness.map_not_move_message")}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Progress({ is_moved_car, is_arrived_car }) {
  return (
    <>
      {is_moved_car ? (
        <div className='d-flex'>
          <div className='d-flex flex-column justify-content-between align-items-center flex-nowrap'>
            <>
              <CarMove />
            </>
            <div
              className='h-full'
              style={{
                backgroundColor: "#F2C94C",
                width: "2px",
                height: "73px",
                margin: "-2px 0",
              }}
            ></div>
            <>
              <CarArrived fill={is_arrived_car ? "#249856" : "#6A6A6A"} />
            </>
          </div>
          <div
            className='d-flex flex-column justify-content-between'
            style={{ margin: "0 10px" }}
          >
            <p style={detailText}>{trans("flow_flatness.car_start_move")}</p>
            <p style={detailText}>
              {is_arrived_car
                ? trans("flow_flatness.car_arrived")
                : trans("flow_flatness.car_in_way")}
            </p>
          </div>
        </div>
      ) : (
        <div>{trans("flow_flatness.car_not_move")}</div>
      )}
    </>
  );
}

function MapComponent({ currently, interfaceLocation }) {
  // Api Key
  const YOUR_API_KEY = "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo";
  // Api Key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: YOUR_API_KEY,
    language: i18n.language,
  });
  // style
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  //need to get the location of vendor and updated each ten seconds
  return (
    <>
      {isLoaded ? (
        <GoogleMap zoom={8} mapContainerStyle={mapStyles} center={currently}>
          <MarkerF
            position={currently}
            icon={{ url: "/Assets/small_truck.svg" }}
          />
          <MarkerF position={interfaceLocation} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}
export default OrderFlat;
