import React, { useMemo } from "react";
import { trans } from "../../../../Components/Navbar/Navbar";

import ScrollarComponent from "../../../../Components/ScrollarComponent/ScrollarComponent";
import { useState } from "react";

import ContactBox from "./ContactBox";
import SelectedChat from "./SelectedChat";
import ControllData from "../../../../Components/ControllData";

function getTimeFromDateString(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Determine if it's AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle noon (12 hours)

  // Adding leading zeros if hours or minutes are less than 10
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${ampm}`;
}
export default function ChatScreen({ messages, fetchConnectsData }) {

  


  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const authUser = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const openChat = (selectedChat) => {
    setSelectedChat(selectedChat);
  };
  const connects = useMemo(() => {
    return messages.data.map((msg) => {
      const { last_message, shop_ad_id } = msg;
      const { from_user_id, to_user_id, to, from, message, created_at } =
        last_message;
      const user_is_sender = from_user_id === authUser.id;
      const user_is_reciver = to_user_id === authUser.id;
      return {
        ad_id: shop_ad_id,
        connect_name:
          (user_is_sender && to.name) || (user_is_reciver && from.name),
        message,
        time: getTimeFromDateString(created_at),
        user_id:
          (user_is_sender && to_user_id) || (user_is_reciver && from_user_id),
      };
    });
  }, [messages.data]);

  return (
    <div
      className='AllPageMessage py-4 px-0 px-lg-3'
      style={{ minHeight: "600px" }}
    >
      <div className='head'>
        <h3 className='fs-24-600'>{trans("harag_message.title")}</h3>
        <p>{trans("harag_message.disc")}</p>
      </div>
      {/* cont */}
      <div className='cont mt-5 px-3 px-md-4 bg-white overflow-hidden r-10'>
        {/* All Message */}
        <div className='list-window bg-white' style={{ overflowY: "auto" }}>
          <div className='all-message py-4'>
            <span className='message-count fs-20-600'>{`${trans(
              "harag_message.all_message",
            )} (${connects.length})`}</span>
            <div className='side mt-4'>
              <ScrollarComponent height='100%'>
                <div className='d-flex flex-column gap-2'>
                  {connects.map((connect) => (
                    <ContactBox
                      key={connect.time}
                      {...{ connect, openChat, setLoading, token, setStatus }}
                    />
                  ))}
                  <ControllData
                    {...{
                      conditionToMore:
                        messages.data.length >= 20 && messages.next,
                      conditionToLess:
                        messages.data.length > 20 && !messages.next,
                      onMore: () => fetchConnectsData(messages.next, true),
                      onLess: () => fetchConnectsData(messages.prev),
                    }}
                  />
                </div>
              </ScrollarComponent>
            </div>
          </div>
        </div>
        {/* Content Message */}
        <SelectedChat
          fetchConnectsData={fetchConnectsData}
          selectedChat={selectedChat}
          auth_id={authUser.id}
          ad_id={messages.data.shop_ad_id}
          status={status}
          setStatus={setStatus}
        />
      </div>
    </div>
  );
}
