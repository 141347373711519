import React from "react";
import RegisterData from "../RegisterData/RegisterData";
import Orders from "../Orders/Orders";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useGetDefaultLocattion from "../../../hooks/useDefaultLocation";
import { useRecoilValue } from "recoil";
import { UserDefaultLocation } from "../../../Recoil/location";
import useGetCarLocation from "../../../hooks/useSendCarLocation";
import useCheckConnect from "../../../hooks/useCheckConnect";
import useGetActiveUsers from "../../../hooks/useGetActiveUsers";

function VendorHome() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const { order_id } = useParams();
  //get default location for the vendor

  const isOnline = useCheckConnect();
  const carLocation = useGetCarLocation(
    (user && user.service_type) === "flatness" && isOnline,
  );

  return (
    <>
      {user?.is_register_vendor_data ? (
        <Orders order_id={order_id} />
      ) : (
        user && <RegisterData setUser={setUser} />
      )}
    </>
  );
}

export default VendorHome;
