import "./Message.css";
import { trans } from "../../../../../../Components/Navbar/Navbar";
import { ReactComponent as Send } from "./Assets/paper-airplane.svg";
import { ReactComponent as UploadFile } from "./Assets/upload_file.svg";
import { ReactComponent as UploadImage } from "./Assets/upload_image.svg";
import { ReactComponent as InsertLink } from "./Assets/insert_link.svg";
import { ReactComponent as Chat } from "./Assets/chat.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../../Components/Loader/Loader";
import axios from "axios";
import { basedDomin } from "../../../../../../Api/basedDomin";
import {
  ErrorComponent,
  SuccsesComponent,
} from "../../../../../../Others/Error";
import { isValidURL } from "./Utls";
function MessageComponent({ data }) {
  const { ad } = data;

  // Main State
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorValidation, setErrorValidation] = useState({});
  const [isImg, setIsImg] = useState(false);
  const [message, setMessage] = useState("");
  const [isLink, setIsLink] = useState(true);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [changeFile, setChangeFile] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (!isLink) {
        if (!isValidURL(message)) {
          throw new Error("This URL is Not valid, Please insert a valid URL");
        }
      }
      if (message) {
        const { data } = await axios.post(
          `${basedDomin}/shop/chat/ads/${ad.id}/send/user/${ad.owner.id}`,
          { message },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        SuccsesComponent(data.message);
        setLoader(false);
      } else if (file || image) {
        const fileToSend = file || image;
        const { data } = await axios.post(
          `${basedDomin}/shop/chat/ads/${ad.id}/send/user/${ad.owner.id}`,
          { file: fileToSend },
          {
            headers: {
              Accept: "application/json",
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        SuccsesComponent(data.message);
        setLoader(false);
      }
    } catch (error) {
      ErrorComponent(error, navigate, setErrorValidation);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (changeFile === "file" && file) {
      setImage(null);
    } else if (changeFile === "image" && image) setFile(null);
    else {
      setChangeFile("");
      setFile(null);
      setImage(null);
    }
  }, [file, image]);

  const onChange = (e) => {
    if (isImg) {
      setImage(e.target.files[0]);
      e.target.value = "";
    } else {
      setFile(e.target.files[0]);
      e.target.value = "";
    }

    setChangeFile(isImg ? "image" : "file");
  };
  return (
    <div className='MessageComponent'>
      <span className='title-popup d-block mb-4'>
        {!isLink
          ? trans("ads_details.popup_message.label_url")
          : trans("ads_details.popup_message.label_text")}
      </span>

      <form onSubmit={submit} className=' d-flex flex-column gap-4'>
        {image ? (
          <Image {...{ image, setImage }} />
        ) : file ? (
          <File {...{ file, setFile }} />
        ) : (
          <textarea
            className={`full-width d-block r-10 p-3`}
            rows='4'
            placeholder={trans("ads_details.popup_message.placeholder")}
            style={{ backgroundColor: "#F7F7F7" }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        )}

        {loader ? (
          <Loader width={"30px"} />
        ) : (
          <div
            className='d-flex justify-content-end'
            style={{
              width: "100%",
              height: "56px",
              borderRadius: 8,
              border: "0.50px #D2D2D2 solid",
              overflow: "hidden",
            }}
          >
            <MessageActionButton
              gray={true}
              onClick={() => {
                setIsImg(false);
              }}
              isImg={isImg}
              onChange={onChange}
            >
              <span>
                <UploadFile />
              </span>
              <span>
                {trans("ads_details.popup_message.media.upload_file")}
              </span>
            </MessageActionButton>
            <MessageActionButton
              gray={true}
              onClick={() => {
                setIsImg(true);
              }}
              isImg={isImg}
              onChange={onChange}
            >
              <span>
                <UploadImage />
              </span>
              <span>
                {trans("ads_details.popup_message.media.upload_image")}
              </span>
            </MessageActionButton>
            <MessageActionButton
              gray={true}
              disabled={image || file}
              upload={false}
              onClick={() => setIsLink((prevState) => !prevState)}
              style={{ cursor: "not-allowed" }}
            >
              <span>{isLink ? <InsertLink /> : <Chat />}</span>
              <span>
                {isLink
                  ? trans("ads_details.popup_message.media.insert_link")
                  : trans("ads_details.popup_message.media.send_text")}
              </span>
            </MessageActionButton>
            <MessageActionButton upload={false} disabled={loader} type='submit'>
              <span>
                <Send />
              </span>
              <span>{trans("ads_details.popup_message.send")}</span>
            </MessageActionButton>
          </div>
        )}
      </form>
    </div>
  );
}

function MessageActionButton({
  children,
  gray = false,
  upload = true,
  disabled = false,
  onClick,
  type = "button",
  isImg,
  onChange,
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`border-0 bg-main ${
        !gray && "text-white"
      } flex-c r-10 d-flex align-items-center gap-2`}
      style={{
        cursor: "pointer",
        position: "relative",
        width: "25%",
        height: "56px",
        marginTop: 0,
        borderRadius: 0,
        backgroundColor: `${gray && "#ffffff"}`,
        color: `${gray && "#6A6A6A"}`,
      }}
    >
      {upload && (
        <input
          type='file'
          onChange={(e) => {
            onChange(e);
          }}
          style={{
            opacity: 0,
            cursor: "pointer",
            position: "absolute",
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {children}
    </button>
  );
}

function Image({ image, setImage }) {
  return (
    <div className='images-up mt-4 mb-4'>
      <div
        className='image position-relative flex-c bg-image'
        style={{
          backgroundImage: `url(${URL.createObjectURL(image)})`,
          minHeight: "250px",
        }}
      >
        <span
          className='close flex-c'
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "9px",
            left: "13px",
            background: "#FF0000",
            padding: "0 10px",
            color: "#ffffff",
          }}
          onClick={() => {
            setImage(null);
          }}
        >
          x
        </span>
      </div>
    </div>
  );
}

function File({ file, setFile }) {
  return (
    <div
      className='d-flex align-items-center justify-content-between'
      style={{
        width: "100%",
        height: "56px",
        borderRadius: 8,
        border: "0.50px #D2D2D2 solid",
        padding: "0 12px",
      }}
    >
      <p>{file.name}</p>
      <span
        className='close flex-c'
        style={{ cursor: "pointer" }}
        onClick={() => {
          setFile(null);
        }}
      >
        x
      </span>
    </div>
  );
}
export default MessageComponent;
