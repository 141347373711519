import { atom } from "recoil";

export const myAdsMainData = atom({
  key: "myAdsMainData",
  default: {
    data: [],
    next: null,
    prev: null,
  },
});
