import React, { useRef } from "react";
import { trans } from "../../../../Components/Navbar/Navbar";
import { ReactComponent as User } from "../../Assets/user.svg";
import { ReactComponent as Send } from "../../Assets/send.svg";
import { ReactComponent as Arrow } from "../../Assets/arrow-down.svg";
import DotsMenu from "../../../../Components/DotsMenu/DotsMenu";
import ScrollarComponent from "../../../../Components/ScrollarComponent/ScrollarComponent";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import Loader from "../../../../Components/Loader/Loader";

import TextMessage from "../RequestesMessages/Components/ContentMessages/Components/TextMessage/TextMessage";
import Pusher from "pusher-js";

import { ErrorComponent } from "../../../../Others/Error";
import { getFileType } from "./utils";
import moment from "moment";
import FileIcon from "./Assets/FileIcon";
import useCheckActiveUsers from "../../../../hooks/useCheckTheActiveUser";
const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
export default function SelectedChat({
  selectedChat,
  auth_id,
  fetchConnectsData,
  status,
  setStatus,
}) {
  const [isWritingNow, setIsWritingNow] = useState(false);
  const [userTitmeoutId, setUserTitmeoutId] = useState(null);
  const [messages, setMessages] = useState();
  const [message, setMessage] = useState("");
  const specificSectionRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [errorValidation, setErrorValidation] = useState({});
  const navigate = useNavigate();

  const is_online = useCheckActiveUsers(selectedChat?.user_chat?.id);
  useEffect(() => {
    setMessages(selectedChat?.chat?.messages?.data.sort((a, b) => a.id - b.id));
  }, [selectedChat?.user_chat?.id]);
  useEffect(() => {
    if (specificSectionRef.current) {
      specificSectionRef.current.scrollIntoView({ behavior: "smooth" });
      specificSectionRef.current.scrollTop =
        specificSectionRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    let isThereUpdates = false;
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });

    const channel = pusher.subscribe(
      `user-channel-${auth_id}-chat-shop-ad-${selectedChat?.chat?.shop_ad_id}-user-${selectedChat?.user_chat?.id}`,
    );
    channel.bind(
      `chat-shop-ad-${selectedChat?.chat?.shop_ad_id}-user-${selectedChat?.user_chat?.id}`,
      (message) => {
        fetchConnectsData();
        isThereUpdates = true;
        setMessages((prevMessages) => [...prevMessages, message.data]);
      },
    );
    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, [selectedChat]);
  const submit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${basedDomin}/shop/chat/ads/${selectedChat?.chat?.shop_ad_id}/send/user/${selectedChat?.user_chat?.id}`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      await fetchConnectsData();
      setLoader(false);
      setMessages([...messages, data.data]);
      setMessage("");
    } catch (error) {
      ErrorComponent(error, navigate, setErrorValidation);
      setLoader(false);
    }
  };
  const handleChange = async () => {
    // Clear any existing timeout
    if (userTitmeoutId) {
      clearTimeout(userTitmeoutId);
    }

    // Set a new timeout that will call the buyerIsWiriting function after  3 seconds
    const newTimeoutId = setTimeout(() => {
      userIsWiriting();
    }, 3000);

    // Store the timeout ID
    setUserTitmeoutId(newTimeoutId);
  };
  async function userIsWiriting() {
    //{{URL}}/shop/chat/ads/{{$ads_id}}/writing-now/user/{{$to_user_id}}
    try {
      const { data } = await axios.post(
        `${basedDomin}/shop/chat/ads/${selectedChat?.chat?.shop_ad_id}/writing-now/user/${selectedChat?.user_chat?.id}`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    let isThereUpdates = false;
    // Pusher Get New Message
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });
    console.log(
      `writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}-writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}`,
    );
    const channel = pusher.subscribe(
      `writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}-writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}`,
    );
    channel.bind(
      `writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}`,
      ({ data }) => {
        isThereUpdates = true;
        setIsWritingNow(true);
      },
    );

    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, [selectedChat?.chat?.shop_ad_id, selectedChat?.user_chat?.id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isWritingNow) {
        setIsWritingNow(false);
        console.log(
          `writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}-writing-now-ads-${selectedChat?.chat?.shop_ad_id}-user-${user.id}`,
        );
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isWritingNow]);

  return (
    <>
      <div
        className={`control transion-5 full-width pt-4 ${status && "right-0"}`}
      >
        <div className='content-message'>
          {/* Head */}

          <div
            className={`head-message d-flex gap-4 ${
              selectedChat ? "justify-content-between" : "justify-content-end"
            } border-bottom pb-3`}
          >
            {selectedChat && (
              <div className='d-flex align-items-center gap-3 px-3'>
                <div className='image flex-c'>
                  <User />
                </div>
                <div className='text'>
                  <div className='d-flex align-items-center content-justify-between gap-2'>
                    <h4>{selectedChat.user_chat.name}</h4>
                    <p className='fs-12-400 text-color'>
                      {isWritingNow && trans("harag_message.writing_now")}
                    </p>
                  </div>

                  <p className='mt-1'>
                    {is_online && trans("harag_message.active_now")}
                  </p>
                </div>
              </div>
            )}
            <div className='ctr d-flex align-items-center gap-2'>
              <DotsMenu>
                <p className='p-2'>hello</p>
              </DotsMenu>
              <div
                className='back pointer px-3 d-md-none'
                onClick={() => {
                  setStatus(false);
                }}
              >
                <Arrow />
              </div>
            </div>
          </div>
          {/* container message */}

          <div
            className='container-message p-3'
            style={{ height: "300px", overflowY: "auto" }}
            ref={specificSectionRef}
          >
            {!selectedChat ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ height: "100%" }}
              >
                <p className='fs-20-600'>قم باختيار محادثة لتبدأ التواصل</p>
              </div>
            ) : (
              <ScrollarComponent>
                {messages &&
                  messages.map((msg, idx) => {
                    if (
                      msg.type === "file" &&
                      getFileType(msg.message) === "img"
                    ) {
                      return (
                        <div style={{ marginBottom: "12px" }}>
                          <ImgMessage
                            key={idx}
                            {...{
                              message: msg.message,
                              created_at: msg.created_at,
                              from_user_id: msg.from_user_id,
                            }}
                          />
                        </div>
                      );
                    } else if (
                      msg.type === "file" &&
                      getFileType(msg.message) === "doc"
                    ) {
                      return (
                        <div style={{ marginBottom: "12px" }}>
                          <FileMessage
                            key={idx}
                            {...{
                              message: msg.message,
                              created_at: msg.created_at,
                              from_user_id: msg.from_user_id,
                            }}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ marginBottom: "12px" }}>
                          <TextMessage
                            key={idx}
                            {...{
                              message: msg.message,
                              created_at: msg.created_at,
                              from_user_id: msg.from_user_id,
                            }}
                          />
                        </div>
                      );
                    }
                  })}
              </ScrollarComponent>
            )}
          </div>
          {/* Text Send Message */}
          {selectedChat && (
            <div className='send-message border-top'>
              <form onSubmit={submit} className='input-message d-flex'>
                <input
                  placeholder={trans("harag_message.write_message")}
                  className={`flex-grow-1 border-0 bg-transparent`}
                  type='text'
                  value={message}
                  onChange={(e) => {
                    handleChange();
                    setMessage(e.target.value);
                  }}
                />
                <button
                  className={`icon ${loader ? "bg-dark" : "bg-green"} pointer`}
                  disabled={loader}
                >
                  {loader ? <Loader width={"25px"} /> : <Send />}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function ImgMessage({ message, created_at, from_user_id }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const lngDir = localStorage.getItem("i18nextLng");
  // Moment
  var timeago;
  if (localStorage.getItem("i18nextLng") === "ar") {
    timeago = moment(created_at).locale("ar").format("h:mm a");
  } else {
    timeago = moment(created_at).locale("en").format("h:mm a");
  }
  // Moment
  return (
    <a
      href={message}
      target='_blanck'
      className={`TextMessage d-flex ${
        from_user_id === user?.id ? "justify-content-end" : ""
      }`}
    >
      <div
        className={`Text ${
          from_user_id === user?.id ? "bg-main text-white" : "bg-gray text-dark"
        }`}
        style={{ maxWidth: "200px", maxHeight: "250px", padding: "5px 10px" }}
      >
        <img
          src={`${message}`}
          alt='ad img'
          style={{ maxWidth: "180px", maxHeight: "250px" }}
        />
        <span>{timeago}</span>
      </div>
    </a>
  );
}
function FileMessage({ message, created_at, from_user_id }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const lngDir = localStorage.getItem("i18nextLng");
  // Moment
  var timeago;
  if (localStorage.getItem("i18nextLng") === "ar") {
    timeago = moment(created_at).locale("ar").format("h:mm a");
  } else {
    timeago = moment(created_at).locale("en").format("h:mm a");
  }
  // Moment
  return (
    <a
      href={message}
      target='_blanck'
      className={`TextMessage d-flex ${
        from_user_id === user?.id ? "justify-content-end" : ""
      }`}
    >
      <div
        className={`Text ${
          from_user_id === user?.id ? "bg-main text-white" : "bg-gray text-dark"
        }`}
        style={{ maxWidth: "200px", maxHeight: "250px", padding: "5px 10px" }}
      >
        <div
          className='d-flex align-items-center justify-content-between'
          style={{
            minWidth: "180px",
            height: "56px",
            borderRadius: 8,
            border: "0.50px #D2D2D2 solid",
            padding: "0 12px",
          }}
        >
          <p>{"document"}</p>
          <FileIcon fill={`${from_user_id === user?.id && "#ffffff"}`} />
        </div>
        <span>{timeago}</span>
      </div>
    </a>
  );
}
