import axios from "axios";
import { basedDomin } from "../Api/basedDomin";
import { notificationsTypeState } from "../Recoil/Notifications";
import { useRecoilState, useRecoilValue } from "recoil";
import { ErrorComponent } from "../Others/Error";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";

import usePusher from "./usePusher";
import Swal from "sweetalert2";
import { trans } from "../Components/Navbar/Navbar";

const alert_toast = (icon, text, Swal) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: text,
  });
};

const token = localStorage.getItem("token");
const authUser = JSON.parse(localStorage.getItem("user"));
const authId = authUser ? authUser.id : "";

export default function useGetNotifications() {
  const [notifications, setNotifications] = useRecoilState(
    notificationsTypeState,
  );
  
  const navigate = useNavigate();
  const getNotifications = useCallback(
    (URL, more = false) => {
      if (token) {
        axios
          .get(URL || `${basedDomin}/public/notifications`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then(({ data }) => {
            let newNotifications = data.data.notifications.data;
            if (more) {
              newNotifications = [
                ...notifications.data,
                ...data.data.notifications.data,
              ];
            }
            setNotifications({
              data: newNotifications,
              next: data.data.notifications.links.next,
              first: data.data.notifications.links.first,
            });
          })
          .catch((error) => {
            ErrorComponent(error, navigate);
          });
      }
    },
    [navigate, setNotifications],
  );

  usePusher("orders-service-flatness", "orders", (notification) => {
    if (authUser) {
      setNotifications((prevNotifications) => {

        return {
          ...prevNotifications,
          data: [notification, ...prevNotifications.data],
        };
      });
      alert_toast("info", trans("home.nav.new-noti"), Swal);
    }
  });
  usePusher("orders-service-maintenance_services", "orders", (notification) => {
    if (authUser) {
      setNotifications((prevNotifications) => {
        return {
          ...prevNotifications,
          data: [notification, ...prevNotifications.data],
        };
      });
      alert_toast("info", trans("home.nav.new-noti"), Swal);
    }
  });
  usePusher("orders-service-spare_parts", "orders", (notification) => {
    if (authUser) {
      setNotifications((prevNotifications) => {
        return {
          ...prevNotifications,
          data: [notification, ...prevNotifications.data],
        };
      });
      alert_toast("info", trans("home.nav.new-noti"), Swal);
    }
  });
  usePusher(`user-channel-${authId}-event`, "event", (notification) => {
    if (authUser) {
      setNotifications((prevNotifications) => {
        return {
          ...prevNotifications,
          data: [notification, ...prevNotifications.data],
        };
      });
      alert_toast("info", trans("home.nav.new-noti"), Swal);
    }
  });

  return getNotifications;
}
