import React, { useEffect, useState } from "react";
import "./RegisterData.css";
import { trans } from "../../../Components/Navbar/Navbar";
import ReactSelect from "react-select";
import { colorStyles } from "../../../Others/ColorStyleReactSlick";
import UploadImage from "./Components/UploadImage";
import InputData from "../Components/InputData/InputData";
import CountriesAndCites from "../../../Components/CountriesAndCites/CountriesAndCites";
import { ReactComponent as Map } from "./Assets/map.svg";
import SelectLocationFromMap from "../../../Components/SelectLocationFromMap/SelectLocationFromMap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { vendorMainLoader } from "../GlopalStateRecoil/AllData";
import axios from "axios";
import { basedDomin } from "../../../Api/basedDomin";
import { ErrorComponent, SuccsesComponent } from "../../../Others/Error";

function RegisterData({ setUser }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [viewMap, setViewMap] = useState(false);
  const [loader, setLoader] = useRecoilState(vendorMainLoader);
  // State
  const [active, setActive] = useState(false);
  const [errorValidation, setErrorValidation] = useState({});
  const [image, setImage] = useState("");
  const [nameMarket, setNameMarket] = useState("");
  const [streetName, setStreetName] = useState("");
  const [countries, setCountries] = useState([]);
  const [citySelect, setCitySelect] = useState([]);
  const citiesArray = Array.isArray(citySelect)
    ? citySelect.map((item) => item.value)
    : [citySelect?.value];
  const [marketDesc, setMarketDesc] = useState("");
  // State
  // Name Market
  const nameMarketObj = {
    title: trans("vendor.register.name_market"),
    placeholder: trans("vendor.register.name_market_write"),
    state: nameMarket,
    setState: setNameMarket,
  };
  // Name Market
  // Street Name
  const streetNameObj = {
    title: trans("vendor.register.street_name"),
    placeholder: trans("vendor.register.street_place"),
    state: streetName,
    setState: setStreetName,
  };
  // Street Name
  // Type Serves
  const [typeServes, setTypeServes] = useState();
  const typeServesOption = [
    {
      label: trans("home.order.spare"),
      value: "spare_parts",
    },
    {
      label: trans("home.order.maintenance"),
      value: "maintenance_services",
    },
    {
      label: trans("home.order.flatness"),
      value: "flatness",
    },
  ];
  const selectTypeServes = (data) => {
    setTypeServes(data);
  };

  // Type Serves
  // Type Workshop
  const [typeWorkshope, setWorkshop] = useState();
  const typeWorkshopOptions = [
    {
      label: trans("order_workshop.mechanical"),
      value: "mechanical",
    },
    {
      label: trans("order_workshop.electrical"),
      value: "electrical",
    },
    {
      label: trans("order_workshop.plumber"),
      value: "plumber",
    },
  ];
  const selectTypeWorkshop = (data) => {
    setWorkshop(data);
  };
  // Type Workshop
  // Type Btn
  // const [newSpare, setNewSpare] = useState(false);
  // const [oldSpare, setOldSpare] = useState(false);
  const [spareType, setSpareType] = useState({
    import_spare_parts: "0",
    new_spare_parts: "0",
  });
  const selectTypeOfSpare = (data) => {
    let selectedSpareType = spareType;
    if (data === "old") {
      selectedSpareType.import_spare_parts = "1";
      selectedSpareType.new_spare_parts = "0";
    } else {
      selectedSpareType.import_spare_parts = "0";
      selectedSpareType.new_spare_parts = "1";
    }
    setSpareType(selectedSpareType);
  };
  // Type Btn
  // Countries And Cityes
  const adressObj = {
    errorValidation,
    setActive,
    countries,
    setCountries,
    citySelect,
    setCitySelect,
    multi: typeServes?.value === "flatness" ? true : false,
  };
  // Countries And Cityes
  // Location
  const [currently, setCurrently] = useState({
    lat: 0,
    lng: 0,
  });
  const mapLocation = {
    currently,
    setCurrently,
  };
  //
  // Function Submit
  const formData = {
    service_type: typeServes?.value,
    lat: currently.lat,
    long: currently.lng,
    shop_image: image,
    shop_name: nameMarket,
    import_spare_parts: spareType.import_spare_parts,
    new_spare_parts: spareType.new_spare_parts,
    country_id: countries?.value,
    cities: citiesArray,
    maintenance_services_type: typeWorkshope?.value,
    street_name: streetName,
    shop_details: marketDesc,
  };
  const submit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${basedDomin}/vendor/register-data`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      SuccsesComponent(data.message);
      setLoader(false);
      localStorage.setItem("user", JSON.stringify(data.data));
      setUser(data.data);
    } catch (error) {
      ErrorComponent(error, navigate, setErrorValidation);
      setActive(false);
      setLoader(false);
    }
  };
  // Function Submit
  useEffect(() => {
    setCitySelect([]);
  }, [typeServes]);
  return (
    <>
      {console.log("errorValidation", errorValidation)}
      {viewMap && (
        <SelectLocationFromMap
          setViewMap={setViewMap}
          mapLocation={mapLocation}
        />
      )}
      <div className='RegisterData mt-4 mt-md-5 pb-4 pb-md-5'>
        <div className='container'>
          <h3 className='fs-24-700 mb-4'>{trans("vendor.register.title")}</h3>
          <div className='row g-4'>
            <div className='col-12 col-lg-8'>
              <form onSubmit={submit} className='d-flex flex-column gap-4'>
                {/* Type Serves */}
                <div className='d-flex flex-column gap-12'>
                  <span className='span'>
                    {trans("vendor.register.servece_title")}
                  </span>
                  <ReactSelect
                    options={typeServesOption}
                    value={typeServes}
                    placeholder={trans("vendor.register.servece_place")}
                    isSearchable={true}
                    onChange={selectTypeServes}
                    styles={colorStyles}
                  />
                  {errorValidation.hasOwnProperty("service_type") && (
                    <span className='text-error fs-14-400'>
                      {errorValidation.service_type[0]}
                    </span>
                  )}
                </div>
                {/* CheckBox */}
                {typeServes?.value === "spare_parts" && (
                  <div className='d-flex flex-column gap-12'>
                    <span className='span'>
                      {trans("vendor.register.market_atvity")}
                    </span>
                    <ReactSelect
                      options={[
                        { label: trans("home.order.spare"), value: "new" },
                        { label: trans("vendor.register.old"), value: "old" },
                      ]}
                      placeholder={trans("vendor.register.market_atvity")}
                      onChange={selectTypeOfSpare}
                      styles={colorStyles}
                    />
                  </div>
                  // <div className='d-flex flex-column gap-12'>
                  //   <span className='span'>
                  //     {trans("vendor.register.market_atvity")}
                  //   </span>
                  //   <div className='checkboxes-register d-flex gap-3'>
                  //     {/* New */}
                  //     <label className={`pointer`}>
                  //       <input
                  //         type='checkbox'
                  //         className='d-none'
                  //         checked={newSpare}
                  //         onChange={(e) => {
                  //           setNewSpare(!newSpare);
                  //         }}
                  //       />
                  //       {trans("home.order.spare")}
                  //     </label>
                  //     {/* Old */}
                  //     <label className={`pointer`}>
                  //       <input
                  //         type='checkbox'
                  //         className='d-none'
                  //         checked={oldSpare}
                  //         onChange={(e) => {
                  //           setOldSpare(!oldSpare);
                  //         }}
                  //       />
                  //       {trans("vendor.register.old")}
                  //     </label>
                  //   </div>
                  // </div>
                )}
                {/* If Type Serves Be Workshop */}
                {typeServes?.value === "maintenance_services" && (
                  <div className='d-flex flex-column gap-12'>
                    <span className='span'>
                      {trans("vendor.register.workshop_type")}
                    </span>
                    <ReactSelect
                      options={typeWorkshopOptions}
                      value={typeWorkshope}
                      placeholder={trans("vendor.register.workshop_type")}
                      isSearchable={true}
                      onChange={selectTypeWorkshop}
                      styles={colorStyles}
                    />
                  </div>
                )}
                <UploadImage
                  title={
                    typeServes?.value === "flatness"
                      ? trans("vendor.register.flatness_image")
                      : trans("vendor.register.market_image")
                  }
                  image={image}
                  setImage={setImage}
                  errorValidation={errorValidation}
                />
                {errorValidation.hasOwnProperty("shop_image") &&
                  typeServes?.value !== "flatness" && (
                    <span className='text-error fs-14-400'>
                      {errorValidation.shop_image}
                    </span>
                  )}
                {typeServes?.value !== "flatness" && (
                  <>
                    {/* Upload Image */}

                    {/* Name Market */}
                    <InputData {...nameMarketObj} />
                    {errorValidation.hasOwnProperty("shop_name") && (
                      <span className='text-error fs-14-400'>
                        {errorValidation.shop_name}
                      </span>
                    )}
                    {/* Street Name */}
                    <InputData {...streetNameObj} />
                    {errorValidation.hasOwnProperty("street_name") && (
                      <span className='text-error fs-14-400'>
                        {errorValidation.street_name}
                      </span>
                    )}
                  </>
                )}
                {/* Adress */}
                <div className='row g-4'>
                  <CountriesAndCites {...adressObj} />
                </div>

                {/* Market Description */}
                {typeServes?.value !== "flatness" && (
                  <div className='d-flex flex-column gap-12'>
                    <span className='span'>
                      {trans("vendor.register.market_desc")}
                    </span>
                    <textarea
                      defaultValue={marketDesc}
                      className='bg border-0 r-10 p-3'
                      cols='30'
                      rows='5'
                      placeholder={trans("vendor.register.write_here")}
                      onChange={(e) => {
                        setMarketDesc(e.target.value);
                      }}
                    ></textarea>
                  </div>
                )}
                {/* Submit */}
                <button
                  className='btn-blue'
                  type='submit'
                  style={{ maxWidth: "300px" }}
                >
                  {trans("vendor.register.start_now")}
                </button>
              </form>
            </div>
            <div className='col-12 col-lg-4 d-none d-lg-block'></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterData;
