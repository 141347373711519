import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./Components/Head/Head";
import Footer from "./Components/Footer/Footer";
import NoChatOpen from "./Components/NoChatOpen/NoChatOpen";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import { useRef } from "react";
import ContainerMessages from "../ContainerMessages/ContainerMessages";
import Offers from "./Components/Offers/Offers";
import { basedDomin } from "../../../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../../../Others/Error";
import Loader from "../../../../../../Components/Loader/Loader";
import {
  buyerChangeUserId,
  buyerMessagesChanged,
} from "../../GlopalStateRecoil/AllData";
import AgreementProcess from "./Components/AgreementProcess/AgreementProcess";
import { trans } from "../../../../../../Components/Navbar/Navbar";
import Pusher from "pusher-js";

const token = localStorage.getItem("token");
const authUser = JSON.parse(localStorage.getItem("user"));

function ContentMessages({ order_id, vendor_id }) {
  const [isWritingNow, setIsWritingNow] = useState(false);
  const navigate = useNavigate();
  const refCont = useRef();
  const userId = useRecoilValue(buyerChangeUserId);
  // Get UseR Chat
  const [Messages, setMessages] = useRecoilState(buyerMessagesChanged);
  const [usersChat, setUsersChat] = useState();
  const [exist, setExist] = useState(false);
  const [loader, setLoader] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserChat = (order_id, vendor_id) => {
    setLoader(true);
    axios
      .get(`${basedDomin}/buyer/orders/${order_id}/offers/${vendor_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setUsersChat(data.data);
        setMessages(data.data.messages.data);
        setExist(true);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoader(false);
          setExist(false);
        } else {
          ErrorComponent(error, navigate);
        }
      });
  };
  // Pusher Get New Message
  useEffect(() => {
    console.log("this use Effect has triggered=> ");
    let isThereUpdates = false;
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });

    const channel = pusher.subscribe(
      `user-channel-${authUser.id}-chat-order-${
        order_id || userId?.order_id
      }-user-${vendor_id || userId.vendor_id}`,
    );
    channel.bind(
      `chat-order-${order_id || userId?.order_id}-user-${
        vendor_id || userId.vendor_id
      }`,
      (message) => {
        isThereUpdates = true;
        console.log("message=> ", message);
        setMessages((prevMessages) => [message.data, ...prevMessages]);
      },
    );
    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, [order_id, setMessages, userId?.order_id, userId?.vendor_id, vendor_id]);

  useEffect(() => {
    if (order_id && vendor_id) {
      getUserChat(order_id, vendor_id);
    }
  }, [order_id, vendor_id]);
  useEffect(() => {
    if (userId.order_id !== "default" && userId.vendor_id !== "default") {
      getUserChat(userId.order_id, userId.vendor_id);
    } else {
      setLoader(false);
      setExist(false);
      console.log("Nothing");
    }
  }, [userId.order_id, userId.vendor_id]);
  // Get UseR Chat
  return (
    <div className='ContentMessages d-flex flex-column flex-grow-1'>
      <>
        {loader ? (
          <div className='flex-grow-1 flex-c '>
            <Loader width={"150px"} />
          </div>
        ) : exist ? (
          <>
            <Head {...usersChat} isWritingNow={isWritingNow} />
            {/* Content Message */}
            <div className='message-text d-flex flex-column justify-content-end gap-1 flex-grow-1 px-2 py-1 px-sm-3 position-relative'>
              {/* Offers */}
              {!usersChat.is_done_deal && <Offers {...usersChat} />}
              {/* Nessages */}
              <ContainerMessages margin={usersChat.is_done_deal} />
            </div>
            {/* Agreement process */}
            <AgreementProcess
              setUsersChat={setUsersChat}
              order_id={order_id}
              vendor_id={vendor_id}
              {...usersChat}
            />
            {/* Content Message */}
            {/* Send Message */}
            {usersChat.is_confirm_deal ? (
              <div className='p-3 fs-12-400 bg-green text-center text-white'>
                {trans("requestes_message.done_deal_message")}
              </div>
            ) : (
              <Footer
                order_id={order_id}
                vendor_id={vendor_id}
                refCont={refCont}
                setIsWritingNow={setIsWritingNow}
                isWritingNow={isWritingNow}
              />
            )}
          </>
        ) : (
          <div className=' flex-grow-1 flex-c flex-column gap-4 '>
            <NoChatOpen />
          </div>
        )}
      </>
    </div>
  );
}

export default ContentMessages;
