import { selector } from "recoil";
import { basedDomin } from "../../Api/basedDomin";
import axios from "axios";

export const fetchDataSelector = selector({
  key: "fetchDataSelector",
  get: async ({ get }) => {
    try {
      const { data } = await axios.get(`${basedDomin}/public/data/all`, {
        headers: {
          Accept: "application/json",
        },
      });

      const { brands_car, countries } = data.data;

      return {
        status: 200,
        message: "Success!",
        data: { brands_car, countries },
      };
    } catch (e) {
      // Handle error accordingly
      const error = new Error();
      error.message = e.response.data.message;
      error.status = e.response.status;
      throw error;
    }
  },
});
