import Pusher from "pusher-js";
import { useEffect } from "react";

export default function usePusher(channelPath, eventPath, setState, depns) {
  useEffect(() => {
    let isThereUpdates = false;
    // Pusher Get New Message
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });
    const channel = pusher.subscribe(channelPath);
    channel.bind(eventPath, ({ data }) => {
      setState(data);
    });

    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, []);
}
