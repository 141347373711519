import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { fetchDataSelector } from "../Recoil/API/general";
import { useEffect } from "react";
import { LoaderState, LoaderStateEdit } from "../Recoil/All/Loader";
import { generateYears } from "../Recoil/All/GenerateYears";
import { brandsCarsFromApi, repeatCountries } from "../Recoil/All/GeneralData";
import GetYears from "../Components/GetYears/GetYears";
import useGetActiveUsers from "./useGetActiveUsers";
import useGetNotifications from "./useGetNotifications";
import useGetDefaultLocattion from "./useDefaultLocation";
import Aos from "aos";
import { ErrorComponentAxios } from "../Others/Error";
import { useNavigate } from "react-router-dom";

export default function useGetGeneralData() {
  const fetchData = useRecoilValueLoadable(fetchDataSelector);
  const [loader, setLoader] = useRecoilState(LoaderState);
  const [countriesState, setCountriesState] = useRecoilState(repeatCountries);
  const [brandsCar, setBrandsCar] = useRecoilState(brandsCarsFromApi);
  const [years, setYears] = useRecoilState(generateYears);
  const activeUsers = useGetActiveUsers();
  const getNotifications = useGetNotifications();
  const defaultLocation = useGetDefaultLocattion();
  const navigat = useNavigate();
  // Trigger the fetch operation
  useEffect(() => {
    //get years
    GetYears(setYears);
    getNotifications();
    Aos.init();
    //get brands_car and countries
    switch (fetchData.state) {
      case "hasValue":
        const { data } = fetchData.contents;
        setBrandsCar(data.brands_car);
        setCountriesState(data.countries);
        setLoader(false);

        break;
      case "loading":
        // Fetch operation is in progress
        setLoader(true);

        break;
      case "hasError":
        // Error occurred during fetch
        setLoader(false);
        ErrorComponentAxios(fetchData.contents);
        break;
      default:
        // Handle any other states
        setLoader(false);
        break;
    }
  }, [fetchData]);

  return { loader, countriesState, brandsCar, years };
}
