import React, { useState } from "react";

import RatingView from "../../../../Components/RatingView/RatingView";
import { trans } from "../../../../Components/Navbar/Navbar";
import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import { ErrorComponent, SuccsesComponent } from "../../../../Others/Error";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { blockedUserChangeStatus } from "../../../UserProfile/Pages/RequestesMessages/GlopalStateRecoil/AllData";
import useCheckActiveUsers from "../../../../hooks/useCheckTheActiveUser";

function TopHead({ vendorDetails, vendor_id }) {
  const is_online = useCheckActiveUsers(vendor_id);
  const { vendor, rates } = vendorDetails;
  const { name, image } = vendor;
  const { data } = rates;
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [filterBlockedList, setFilterBlockedList] = useRecoilState(
    blockedUserChangeStatus,
  );
  const blockUser = () => {
    setLoader(true);
    axios
      .post(
        `${basedDomin}/public/block/create`,
        { user_id: vendor_id },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        SuccsesComponent(data.message);
        navigate("/my-profile/requestes-message");
        setFilterBlockedList(!filterBlockedList);
        setLoader(false);
      })
      .catch((error) => {
        ErrorComponent(error, navigate);
        setLoader(false);
      });
  };
  return (
    <>
      <div className='TopHead'>
        <div className='row g-4'>
          <div className='col-12 col-md-8'>
            <div className='main-info d-flex gap-4'>
              <div
                className='image bg-image'
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div className='text'>
                <h4 className='fs-20-600 mt-3'>{name}</h4>
                <div className='lastseen mt-2' style={{ marginBottom: "20px" }}>
                  <span className=' fs-16-400 text-color'>
                    {is_online
                      ? trans("harag_message.active_now")
                      : trans("vendor_details.last_seen")}
                  </span>
                  <span className=' fs-16-500'>{" قبل 57 دقيقة"}</span>
                </div>
                <RatingView rates={data} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='buttons d-flex justify-content-center justify-content-md-end gap-4'>
              <button onClick={blockUser} className='btn-border-blue'>
                {trans("requestes_message.block_vendor")}
              </button>
              <button className='btn-blue'>
                {trans("service_provider.send_message")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHead;
