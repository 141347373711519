import React, { useEffect } from "react";
import { ReactComponent as Location } from "../Assets/location-marker.svg";
import { trans } from "../../../../../../../../Components/Navbar/Navbar";
import i18n from "../../../../../../../../i18n";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

function Details({ to, from }) {
  const [location, setLocation] = React.useState({ from: "", to: "" });
  const centerFrom = {
    lat: from.lat,
    lng: from.lng,
  };
  const centerTo = {
    lat: to.lat,
    lng: to.lng,
  };
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo",
    language: i18n.language,
  });
  const getLocationName = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = new window.google.maps.LatLng(lat, lng);
    let loc = "";
    await geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          loc = results[0].formatted_address;
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
    return loc;
  };

  useEffect(() => {
    (async () => {
      if (isLoaded) {
        const from = await getLocationName(centerFrom.lat, centerFrom.lng);
        const to = await getLocationName(centerTo.lat, centerTo.lng);
        setLocation({ to, from });
      }
    })();
  }, [isLoaded, centerFrom.lat, centerFrom.lng, centerTo.lat, centerTo.lng]);
  return (
    <div className='Details mt-4'>
      <div className='d-flex gap-3 '>
        <div className='icons py-4 d-flex gap-1 flex-column align-items-center justify-content-between'>
          <div className='circle'></div>
          <div className='line'></div>
          <div className='icon'>
            <Location />
          </div>
        </div>
        <div className='location d-flex flex-column justify-content-between'>
          {/* Current */}
          <div className='current p-3 r-07 border d-flex flex-column gap-2'>
            <span>{trans("flanties.current")}</span>
            <span>{location.from ? location.from : "loading..."}</span>
          </div>
          {/* Interface */}
          <div className='interface current p-3 r-07 border d-flex flex-column gap-2'>
            <span>{trans("flanties.interface")}</span>
            <span>{location.to ? location.to : "loading..."}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
