import axios from "axios";
import { basedDomin } from "../../../../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../../../../Others/Error";

// function get type cars
export const getTypeCars = (id, setState, navigate) => {
  axios
    .get(`${basedDomin}/public/data/types-car/${id}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then(({ data }) => {
      setState(data.data);
    })
    .catch((error) => {
      ErrorComponent(error, navigate);
    });
};

const removeDuplicates = (array) => {
  const uniqueValues = new Set();
  return array.filter((obj) => {
    if (!uniqueValues.has(obj.value)) {
      uniqueValues.add(obj.value);
      return true;
    }
    return false;
  });
};
// function get type cars
export const getMultiTypeCars = (id, state, setState, navigate) => {
  axios
    .get(`${basedDomin}/public/data/types-car/${id}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then(({ data }) => {
      const formatData = data.data.map((type) => {
        return { ...type, companyId: id };
      });
      setState(removeDuplicates([...state, ...formatData]));
    })
    .catch((error) => {
      ErrorComponent(error, navigate);
    });
};
