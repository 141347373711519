import React from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import i18n from "../../i18n";
import { UserDefaultLocation } from "../../Recoil/location";
import { useRecoilValue } from "recoil";
const MapComponent = ({
  zoom,
  currently,
  onClickType,
  setCurrently,
  interfaceLocation,
  setInterfaceLocation,
}) => {
  // Api Key
  const YOUR_API_KEY = "AIzaSyAAOcn3r6DVavhuoPatQvNGg5kUuV1zAFo";
  // Api Key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: YOUR_API_KEY,
    language: i18n.language,
  });
  // style
  const mapStyles = {
    height: "100%",
    width: "100%",
  };
  // Default Center
  // Default Center
  const userDefaultLocation = useRecoilValue(UserDefaultLocation);
  // Current
  const onMapClickCurrent = (e) => {
    setCurrently({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };
  // Interface
  const onMapClickInterface = (e) => {
    setInterfaceLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={userDefaultLocation}
          onClick={
            onClickType === "current"
              ? onMapClickCurrent
              : onClickType === "interface"
                ? onMapClickInterface
                : null
          }
        >
          {currently.lat > 0 && <MarkerF position={currently} />}
          {interfaceLocation.lat > 0 && (
            <MarkerF position={interfaceLocation} />
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
};

export default MapComponent;
