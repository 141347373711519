import { useRecoilState, useRecoilValue } from "recoil";
import { trans } from "../../../../Components/Navbar/Navbar";
import Header from "../Components/Header";
import NoOrders from "./Components/NoOrders/NoOrders";
import Taps from "./Components/Taps/Taps";
import "./MyOrder.css";
import {
  ExistStatus,
  TapsType,
  changeData,
  mainDataOrderProcessing,
  myOrderLoader,
  typeServices,
} from "./GlopalStateRecoil/AllData";
import { useEffect } from "react";
import { useGetOrdersProcessing } from "./Api/useGetOrdersProcessing";
import { useNavigate } from "react-router-dom";
function MyOrder() {
  const { exist, loader, allData, getAllOrdersData } = useGetOrdersProcessing();
  // Header Data
  const headers = {
    title: trans("my_order.title"),
    disc: trans("my_order.disc"),
    button: trans("my_order.order_serves"),
    to: "/about-carz",
    buttonStatus: allData?.data.length,
  };
  return (
    <div className='MyOrder py-4 px-0 px-md-3'>
      <Header {...headers} />
      <Taps {...{ getAllOrdersData }} />
    </div>
  );
}

export default MyOrder;
