import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollarComponent from "../../../ScrollarComponent/ScrollarComponent";
import { trans } from "../../../Navbar/Navbar";
import User from "./Components/User";
import { IoClose } from "react-icons/io5";
import {
  blockedUserChangeStatusVendor,
  openListUserMessages,
} from "../../GlopalStateRecoil/AllData";
import { useRecoilState, useRecoilValue } from "recoil";
import NotUser from "./Components/NotUser/NotUser";
import axios from "axios";
import { basedDomin } from "../../../../Api/basedDomin";
import { ErrorComponent } from "../../../../Others/Error";
import { useState } from "react";
import Loader from "../../../Loader/Loader";
import { ActiveUsers } from "../../../../Recoil/ActiveUsers";
import ControllData from "../../../ControllData";

// Sort List User
function compareDates(a, b) {
  const dateA = new Date(a.time);
  const dateB = new Date(b.time);

  if (dateA > dateB) {
    return 1;
  }
  if (dateA < dateB) {
    return -1;
  }
  return 0;
}
function ListedUsers({ users, exist, loader, getUsers }) {
  const [openListUser, setOpenListUser] = useRecoilState(openListUserMessages);
  // Get User Chat
  return (
    <div
      className='all-message full-height d-flex flex-column gap-4'
      style={{ overflowY: "auto" }}
    >
      {/* Head */}
      <div className='top d-flex align-items-center justify-content-between'>
        <span className='message-count fs-20-600'>{`${trans(
          "requestes_message.all_requestes",
        )}`}</span>
        <span
          className='flex-c pointer fs-24-700'
          onClick={() => {
            setOpenListUser(false);
          }}
        >
          <IoClose />
        </span>
      </div>
      {/* Message Tap */}
      <div className='side flex-grow-1'>
        {loader ? (
          <div className=' full-height flex-c'>
            <Loader width={"150px"} />
          </div>
        ) : exist ? (
          <div className='d-flex flex-column gap-2'>
            {users.data.sort(compareDates).map((item, idx) => {
              return <User {...item} key={idx} />;
            })}
            <ControllData
              {...{
                conditionToMore: users.data.length >= 10 && users.next,
                conditionToLess: users.data.length > 10 && !users.next,
                onMore: () => getUsers(users.next, true),
                onLess: () => getUsers(users.prev),
              }}
            />
          </div>
        ) : (
          <div className=' full-height flex-c'>
            <NotUser />
          </div>
        )}
      </div>
    </div>
  );
}

export default ListedUsers;
