import { useNavigate } from "react-router-dom";
import { trans } from "../../../../../Navbar/Navbar";
import { ReactComponent as Send } from "../../../../Assets/send.svg";
import { useRef, useState, useEffect } from "react";
import { ErrorComponent } from "../../../../../../Others/Error";
import axios from "axios";
import { basedDomin } from "../../../../../../Api/basedDomin";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  changeUserId,
  messagesChanged,
} from "../../../../GlopalStateRecoil/AllData";
import Loader from "../../../../../Loader/Loader";
import Pusher from "pusher-js";
// الرواتس اللى هيبعت عليها هما دول

// فى ال buyer
// {{URL}}/buyer/orders/{{$order_id}}/offers/{{$vendor_id}}/writing-now
// فى ال vendor
// {{URL}}/vendor/orders/offers/{{$order_id}}/writing-now

// فى الحراج

// {{URL}}/shop/chat/ads/{{$ads_id}}/writing-now/user/{{$to_user_id}}

// لو تلاحظ هتلاقيهم نفس البراميتر الخاصين بال send message عند كل واحد فيهم

// بالنسبه لل channel اللى هتعمل عليهم استماع فا هما بالشكل ده

// عند ال buyer
// writing-now-order-{{$order_id}}-user-{{$vendor_id}}

// عند ال vendor
// writing-now-order-{{$order_id}}-user-{{$buyer_id}}

// فى الحراج

// writing-now-ads-{{$ads_id}}-user-{{$to_user_id}}
const user = JSON.parse(localStorage.getItem("user"));
// Subscribe to a private or presence channel

function Footer({ order_id, isWritingNow, setIsWritingNow }) {
  //sent that the vendor write
  //case one (vendor is writing)
  //call the end point
  const [VendorTitmeoutId, setVendorTitmeoutId] = useState(null);

  useEffect(() => {
    let isThereUpdates = false;
    // Pusher Get New Message
    const pusher = new Pusher("e1a99b18f88e0adba1aa", {
      cluster: "eu",
    });
    const channel = pusher.subscribe(
      `writing-now-order-${order_id || userId}-writing-now-order-${
        order_id || userId
      }`,
    );
    channel.bind(`writing-now-order-${order_id || userId}`, ({ data }) => {
      isThereUpdates = true;
      console.log("the buyer writing now=> ", data);
      setIsWritingNow(true);
    });

    if (isThereUpdates) {
      return () => {
        // Unsubscribe and disconnect Pusher when component unmounts
        channel.unbind_all();
        channel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isWritingNow) {
        console.log("set iswiriting now to false every five seconds");
        setIsWritingNow(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isWritingNow]);

  const handleChange = async () => {
    // Clear any existing timeout
    if (VendorTitmeoutId) {
      clearTimeout(VendorTitmeoutId);
    }

    // Set a new timeout that will call the vendorIsWriting function after  3 seconds
    const newVendorTitmeoutId = setTimeout(() => {
      vendorIsWriting();
    }, 3000);

    // Store the timeout ID
    setVendorTitmeoutId(newVendorTitmeoutId);
  };
  async function vendorIsWriting() {
    try {
      const { data } = await axios.post(
        `${basedDomin}/vendor/orders/offers/${order_id || userId}/writing-now`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("data when vendor has writin=> ", data);
    } catch (error) {
      console.log(error);
    }
  }

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const ref = useRef();
  const userId = useRecoilValue(changeUserId);
  const [loader, setLoader] = useState(false);
  // Messages Container
  const [Messages, setMessages] = useRecoilState(messagesChanged);
  // Mesage State
  const [message, sendMessage] = useState("");
  // Onsubmit
  const submit = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${basedDomin}/vendor/orders/offers/${order_id || userId}/send-message`,
        { message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setMessages([data.data, ...Messages]);
      setLoader(false);
      ref.current.value = "";
      sendMessage("");
    } catch (error) {
      ErrorComponent(error, navigate);
      setLoader(false);
    }
  };
  // Onsubmit
  return (
    <div className='send-message border-top mb-2'>
      <form onSubmit={submit} className='input-message d-flex'>
        <input
          ref={ref}
          placeholder={trans("harag_message.write_message")}
          className='flex-grow-1 border-0 bg-transparent fs-12-400'
          type='text'
          onChange={(e) => {
            handleChange();
            sendMessage(e.target.value);
          }}
        />
        <button
          type='submit'
          className={`icon border-0 ${loader ? "bg-dark" : "bg-green"}`}
          disabled={loader}
        >
          {loader ? <Loader width={"25px"} /> : <Send />}
        </button>
      </form>
    </div>
  );
}

export default Footer;
